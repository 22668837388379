import _ from 'lodash';
import angular from 'angular';

export class unitService {
    /* ngInject */
    constructor($rootScope, $http, $injector, socket, Auth, $cookies, $location, $q, toastr, $state, $ngConfirm) {
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$injector = $injector;
        this.$cookies = $cookies;
        this.$location = $location;
        this.$q = $q;
        this.$state = $state;
        this.$ngConfirm = $ngConfirm;
        this.socket = socket;
        this.Auth = Auth;
        this.toastr = toastr;
        this.currentRooms = [];
        this.$rootScope.$on('$destroy', () => {
            this.destroyService();
        });
        $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            if (fromState.name === 'login') {
                this.init();
            }
        });
    }

    // Init function, join rooms for account, etc.
    // Destroy service, leave rooms etc.
    // TODO: On: Change account-destroy and init; destroy-destroy service

    init() {
        const self = this;
        self.destroyService();
        if (self.Auth.hasRoleSync('secuvue.SiteView.Settings.UnitConfig.Relays')) {
            self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
            self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
            self.socket.socket.on('unit:relay', self.relayListener.bind(this));
        }
    }

    relayListener(unit) {
        const self = this;
        if (unit.relays[0].engaged) {
            self.toastr.success(`Relay: ${unit.relays[0].alias}, engaged at site: ${unit.siteAlias}`);
        } else {
            self.toastr.success(`Relay: ${unit.relays[0].alias}, disengaged at site: ${unit.siteAlias}`);
        }
    }

    destroyService() {
        const self = this;
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    engageAll(units, siteAlias) {
        const self = this;
        // let relays = _.filter(_.flatten(_.map(units,"relays")),o=>o._id && o.enabled);
        units = _.filter(units, (o) => o.relays && o.relays.length > 0 && o.relays[0]._id && o.relays[0].enabled);
        let randomNum = '';
        const characters = '0123456789';
        for (let i = 0; i < 6; i++) {
            randomNum += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        self.$ngConfirm({
            title: `Engage all relays on: ${siteAlias}`,
            escapeKey: false,
            backgroundDismiss: false,
            // scope: self.$scope,
            // columnClass:"col-md-6 col-md-offset-3",
            content:
            ` <div>
                    To engage all relays on site, please enter <span style='font-weight:bold'>${randomNum}</span>
                </div>
                <input ng-model="confirmNum" type="text" style='width:100%'> </input>
                `,
            buttons: {
                enter: {
                    text: 'Confirm',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        if (scope.confirmNum && scope.confirmNum === randomNum) {
                            units.forEach((u) => {
                                if (u.relays[0].enabled) {
                                    self.$http.post(`/api/units/${u._id}/toggleRelay`, { relayId: u.relays[0]._id, value: true }).then((response) => {
                                        self.toastr.success(`Relay engage request sent: ${u.relays[0].alias}`, {
                                            preventOpenDuplicates: true,
                                        });
                                    });
                                } else {
                                    self.toastr.warning(`Relay not enabled: ${u.relays[0].alias}`, {
                                        preventOpenDuplicates: true,
                                    });
                                }
                            });
                        } else {
                            self.toastr.warning('Incorrect code entered', {
                                preventOpenDuplicates: true,
                            });
                            return false;
                        }
                    },
                },
                close(scope, button) {

                },
            },
        });
    }

    // Engage/Disengage functions
    engageRelay(unitId, relay) {
        const self = this;
        if (relay.enabled) {
            let randomNum = '';
            const characters = '0123456789';
            for (let i = 0; i < 6; i++) {
                randomNum += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            self.$ngConfirm({
                title: `Confirm Engage: ${relay.alias}`,
                escapeKey: false,
                backgroundDismiss: false,
                // scope: self.$scope,
                // columnClass:"col-md-6 col-md-offset-3",
                content:
                ` <div>
                    To engage ${relay.alias}, please enter <span style='font-weight:bold'>${randomNum}</span>
                </div>
                <input ng-model="confirmNum" type="text" style='width:100%'/>
                `,
                buttons: {
                    enter: {
                        text: 'Confirm',
                        btnClass: 'btn-blue',
                        action(scope, button) {
                            if (scope.confirmNum && scope.confirmNum === randomNum) {
                                self.$http.post(`/api/units/${unitId}/toggleRelay`, { relayId: relay._id, value: true }).then((response) => {
                                    self.toastr.success(`Relay engage request sent: ${relay.alias}`, {
                                        preventOpenDuplicates: true,
                                    });
                                    // scope.confirmNum = "";
                                });
                            } else {
                                self.toastr.warning('Incorrect code entered', {
                                    preventOpenDuplicates: true,
                                });
                                return false;
                            }
                        },
                    },
                    close(scope, button) {

                    },
                },
            });
        } else {
            self.toastr.warn(`${relay.alias} is not enabled`);
        }
    }

    disengageRelay(unitId, relay) {
        const self = this;
        self.$ngConfirm({
            title: `Confirm Disengage: ${relay.alias}`,
            escapeKey: false,
            backgroundDismiss: false,
            // scope: self.$scope,
            content:
            `
            <div>
                Please press confirm to disengage relay: ${relay.alias}
            </div>
            `,
            buttons: {
                enter: {
                    text: 'Confirm',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        self.$http.post(`/api/units/${unitId}/toggleRelay`, { relayId: relay._id, value: false }).then((response) => {
                            self.toastr.success('Relay Disengage Request Sent', {
                                preventOpenDuplicates: true,
                            });
                        });
                    },
                },
                close(scope, button) {

                },
            },
        });
    }
}

export default angular.module('cameraViewerMainApp.unitService', [])
    .service('unitService', unitService)
    .name;
