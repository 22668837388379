import c3 from 'c3';
import _ from 'lodash';
import angular from 'angular';
import { formatBytes, formatSeconds, formatLength } from './components/utilities';
import GeneralUtils from '../../../server/components/utilities/general';

// TODO fix unsyncupdates...

export class FileManagerComponent {
    $state;
    $scope;
    $http;
    moment;
    toastr;
    isModal;
    availableSites = [];
    currentSite = undefined;

    /* @ngInject */
    constructor($state, $scope, $http, moment, socket, Auth, tasks, $rootScope, toastr) {
        this.$state = $state;
        this.$scope = $scope;
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.Auth = Auth;
        this.tasksService = tasks;
        this.$rootScope = $rootScope;
        this.toastr = toastr;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.currentAccount = Auth.getCurrentAccountSync().accountId;

        this.usagePerCamChartShow = false;
        this.currentChartView = 'Used';
        this.currentRouteView = undefined;
        this.selectedZone = undefined;
        this.siteUsageData = {};
        this.onlyMotion = false;

        this.availableEncoding = ['H264', 'H265'];
        this.taskTypes = ['retrieveVideo', 'hikvisionVideoRetrieval'];
        this.supportsHikvisionDL = false;

        this.tasks = [];
        this.cameras = [];
        this.siteVideos = [];
        this.currentRooms = [];
        this.cleanUpFuncions = [];
        this.availableCameras = [];
        this.extData = {
            zoneDataUsages: [],
            availableRoutes: [],
            zoneUsageFormatted: [],
            siteSummary: {},
        };

        this.params = {};
        if (this.$state.params) {
            this.params = this.$state.params;
        }

        this.openFlags = {
            incidentDate: false,
            progress: false,
            filter: false,
            video: false,
            storage: false,
            videos: {},
            tasks: {},
        };

        try {
            this.modalInstance = this.$scope.fileManagerModalInstance;
            this.isModal = this.$scope.isModal;
            this.params = this.$scope.getParams();
            // console.log('Params : ', this.params);
        } catch (e) {
            // console.log(e);
        }

        const self = this;

        this.formatLength = formatLength;

        this.prepareRootData = function calcRoot() {
            if (self.siteChartData.Used && self.siteChartData[self.currentChartView]) {
                const routesData = [];
                for (const route in self.siteChartData.Used) {
                    routesData.push([route, self.siteChartData[self.currentChartView][route]]);
                }
                self.charts?.usageChart?.load({
                    columns: routesData,
                });
            } else {
                console.warn('No root use data');
            }
        };

        this.prepareCamData = function calcCam() {
            const cameraUsageData = [];
            const camPercentages = [];
            const camGroup = [];
            let totalUsage = 0;
            self.extData.zoneDataUsages = [];
            self.extData.zoneUsageFormatted = [];
            self.siteUsageData.cameraUsage.forEach((camDoc) => {
                let camTotalUsed = 0;
                const cam = camDoc.key;
                camDoc?.value.forEach((routeDoc) => {
                    if (routeDoc && routeDoc.value && routeDoc.value.size && routeDoc.value.count) {
                        camTotalUsed += routeDoc.value.size;
                    }
                });
                const zone = _.find(
                    self.currentSite.zones,
                    (o) => o.camera === cam || `${o.unit}::${o.camera}` === cam
                );
                if (zone) {
                    self.extData.zoneDataUsages.push([zone.alias, camTotalUsed]);
                    totalUsage += camTotalUsed;
                    camGroup.push(zone.alias);
                    self.extData.zoneUsageFormatted.push({
                        _id: zone._id,
                        alias: zone.alias,
                        total: camTotalUsed,
                        totalFormatted: formatBytes(camTotalUsed),
                        from: zone.oldest ? zone.oldest : 'N/A',
                        to: zone.newest ? zone.newest : 'N/A',
                        percent: 0,
                    });
                }
                const usageOnRoute = _.find(camDoc.value, (o) => o.key === self.currentRouteView);
                if (
                    usageOnRoute &&
                    usageOnRoute.value &&
                    usageOnRoute.value.size !== undefined &&
                    usageOnRoute.value.count !== undefined
                ) {
                    const zone = _.find(
                        self.currentSite.zones,
                        (o) => o.camera === cam || `${o.unit}::${o.camera}` === cam
                    );
                    if (zone) {
                        cameraUsageData.push([zone.alias, usageOnRoute.value.size]);
                        // self.extData.zoneDataUsages.push([zone.alias, usageOnRoute.value.size]);
                        // totalUsage += usageOnRoute.value.size;
                        // camGroup.push(zone.alias);
                        // self.extData.zoneUsageFormatted.push({
                        //     _id: zone._id,
                        //     alias: zone.alias,
                        //     total: camTotalUsed,
                        //     totalFormatted: formatBytes(camTotalUsed),
                        //     from: zone.oldest,
                        //     to: zone.newest,
                        // });
                    }
                } else {
                    const zone = _.find(
                        self.currentSite.zones,
                        (o) => o.camera === cam || `${o.unit}::${o.camera}` === cam
                    );
                    if (zone) {
                        cameraUsageData.push([zone.alias, 0]);
                    }
                }
            });

            cameraUsageData.forEach((usage) => {
                const tableEntry = _.find(
                    self.extData.zoneUsageFormatted,
                    (o) => o.alias === usage[0]
                );
                const percent = (usage[1] / totalUsage) * 100;
                camPercentages.push([usage[0], percent]);
                tableEntry.percent = percent.toFixed(1);
            });

            self.extData.zoneUsageFormatted.sort((i, j) => j.total - i.total);

            if (self.charts.usagePerRouteChart) {
                self.charts.usagePerRouteChart.unload();
                self.charts.usagePerRouteChart.load({
                    columns: cameraUsageData,
                });
            } else {
                console.warn('Chart not yet loaded');
            }

            if (self.charts.usageBarChart) {
                self.charts.usageBarChart.unload();
                self.charts.usageBarChart.load({
                    columns: camPercentages,
                });
                self.charts.usageBarChart.groups([camGroup]);
            } else {
                console.warn('Chart not yet loaded');
            }
        };

        this.renderCharts = function chartRender() {
            // data containers
            const camData = [];
            let storageRouteData = [];
            let camTotalUsed = 0;
            let camAvailable = 0;

            self.charts = {};
            [self.cameraUsage] = self.currentSite.zones;
            [self.selectedZone] = self.currentSite.zones;
            self.currentRouteView = self.siteUsageData?.diskCapacity[0]?.key;

            const cameraString = `${self.selectedZone.unit}::${self.selectedZone.camera}`;
            const zoneUsage = _.find(
                self.siteUsageData.cameraUsage,
                (o) => o.key === self.selectedZone.camera || o.key === cameraString
            );
            const cameraSizeQuotaDoc = _.find(
                self.siteUsageData.cameraSizeQuota,
                (o) => o.key === self.selectedZone.camera || o.key === cameraString
            );
            if (zoneUsage) {
                zoneUsage.value.forEach((routeDoc) => {
                    if (routeDoc && routeDoc.value && routeDoc.value.size && routeDoc.value.count) {
                        camData.push([routeDoc.key, routeDoc.value.size]);
                        camTotalUsed += routeDoc.value.size;
                    }
                });
                self.selectedZone.camTotal = camTotalUsed;
                if (cameraSizeQuotaDoc && cameraSizeQuotaDoc.value !== undefined) {
                    if (cameraSizeQuotaDoc.value > 0) {
                        camAvailable = cameraSizeQuotaDoc.value - camTotalUsed;
                    }
                }

                self.usagePerCamChartShow = camTotalUsed > 0;
            }

            const processStorageRouteData = function calcStor() {
                for (const route in self.siteChartData.Used) {
                    storageRouteData.push([
                        route,
                        self.siteChartData[self.currentChartView][route],
                    ]);
                }
                self.charts?.usageChart?.load({
                    columns: storageRouteData,
                });
            };

            const generateSiteChart = function genSiteChart() {
                return c3.generate({
                    bindto: '#siteChart',
                    data: {
                        columns: [
                            ['Used', self.chartData.Used],
                            ['Available', self.chartData.Available],
                            ['Reserved', self.chartData.Reserved],
                        ],
                        type: 'pie',
                        onclick(d) {
                            self.$scope.$apply(() => {
                                self.currentChartView = d.id;
                                self.charts.siteChart.focus(self.currentChartView);
                            });
                            storageRouteData = [];
                            for (const route in self.siteChartData.Used) {
                                storageRouteData.push([
                                    route,
                                    self.siteChartData[self.currentChartView][route],
                                ]);
                            }
                            self.charts.usageChart.load({
                                columns: storageRouteData,
                            });
                        },
                        onmouseover() {
                            self.charts.siteChart.focus(self.currentChartView);
                        },
                        onmouseout() {
                            self.charts.siteChart.focus(self.currentChartView);
                        },
                    },
                    tooltip: {
                        contents(d, defaultTitleFormat, defaultValueFormat, color) {
                            let info = '';
                            if (d[0].id === 'Available') {
                                info = `${d[0].name} (${formatBytes(d[0].value)}) - ${formatSeconds(
                                    self.chartData.EstimatedRemainingSeconds
                                )} remaining`;
                            } else {
                                info = `${d[0].name} (${formatBytes(d[0].value)})`;
                            }
                            d[0].name = info;
                            return this.getTooltipContent(
                                d,
                                defaultTitleFormat,
                                defaultValueFormat,
                                color
                            );
                        },
                    },
                    legend: {
                        item: {
                            onmouseover() {
                                self.charts.siteChart.focus(self.currentChartView);
                            },
                            onmouseout() {
                                self.charts.siteChart.focus(self.currentChartView);
                            },
                        },
                    },
                    color: {
                        pattern: ['#ff6600', '#009933', '#b3b3b3'],
                    },
                });
            };

            const generateUsageChart = function genUsageChart() {
                return c3.generate({
                    bindto: '#usageChart',
                    data: {
                        columns: storageRouteData,
                        type: 'pie',
                        onclick(d) {
                            self.$scope.$apply(() => {
                                self.currentRouteView = d.id;
                                self.charts.usageChart.focus(self.currentRouteView);
                            });
                            self.prepareCamData();
                        },
                        onmouseover() {
                            self.charts.usageChart.focus(self.currentRouteView);
                        },
                        onmouseout() {
                            self.charts.usageChart.focus(self.currentRouteView);
                        },
                    },
                    tooltip: {
                        contents(d, defaultTitleFormat, defaultValueFormat, color) {
                            let info = '';
                            if (self.currentChartView === 'Available') {
                                info = `${d[0].name} (${formatBytes(d[0].value)}) - ${formatSeconds(
                                    self.siteChartData.EstimatedRemainingSeconds[d[0].id]
                                )} remaining`;
                            } else {
                                info = `${d[0].name} (${formatBytes(d[0].value)})`;
                            }
                            d[0].name = info;
                            return this.getTooltipContent(
                                d,
                                defaultTitleFormat,
                                defaultValueFormat,
                                color
                            );
                        },
                    },
                    legend: {
                        item: {
                            onmouseover() {
                                self.charts.usageChart.focus(self.currentRouteView);
                            },
                            onmouseout() {
                                self.charts.usageChart.focus(self.currentRouteView);
                            },
                        },
                    },
                });
            };

            const generateRouteChart = function genRouteChart() {
                return c3.generate({
                    bindto: '#usagePerRouteChart',
                    data: {
                        columns: [],
                        type: 'pie',
                    },
                    tooltip: {
                        contents(d, defaultTitleFormat, defaultValueFormat, color) {
                            d[0].name = `${d[0].name} (${formatBytes(d[0].value)})`;
                            return this.getTooltipContent(
                                d,
                                defaultTitleFormat,
                                defaultValueFormat,
                                color
                            );
                        },
                    },
                });
            };

            const generateBarChart = function genRouteChart() {
                return c3.generate({
                    bindto: '#usageBarChart',
                    data: {
                        columns: [],
                        groups: [],
                        type: 'bar',
                    },
                    size: {
                        height: 100,
                    },
                    bar: {
                        width: {
                            ratio: 1.0,
                        }                    
                    },
                    tooltip: {
                        grouped: false,
                        contents(d, defaultTitleFormat, defaultValueFormat, color) {
                            const title = d[0].name;
                            const titleFormat = function () {
                                return title;
                            };
                            const percentageValueFormat = function (value, ratio, id) {
                                return `${value.toFixed(1)}%`;
                            };
                            self.extData.zoneDataUsages.forEach((item) => {
                                if (item[0] === d[0].name) {
                                    d[0].name = `${formatBytes(item[1])}`;
                                }
                            });
                            return this.getTooltipContent(
                                d,
                                titleFormat,
                                percentageValueFormat,
                                color
                            );
                        },
                    },
                    padding: {
                        top: 0,
                        bottom: 0,
                        left: 15,
                        right: 15,
                    },
                    axis: {
                        rotated: true,
                        y: {
                            show: false,
                            padding: { top: 0, bottom: 0, left: 0, right: 0 },
                        },
                        x: {
                            show: false,
                            padding: { top: 0, bottom: 0, left: 0, right: 0 },
                        },
                    },
                });
            };

            const generatePerCamChart = function genCamChart() {
                return c3.generate({
                    bindto: '#usagePerCamChart',
                    data: {
                        columns: camData,
                        type: 'pie',
                    },
                    tooltip: {
                        contents(d, defaultTitleFormat, defaultValueFormat, color) {
                            d[0].name = `${d[0].name} (${formatBytes(d[0].value)})`;
                            return this.getTooltipContent(
                                d,
                                defaultTitleFormat,
                                defaultValueFormat,
                                color
                            );
                        },
                    },
                });
            };

            const generateAvailableChart = function genAvailableChart() {
                return c3.generate({
                    bindto: '#availablePerCamChart',
                    data: {
                        columns: [
                            ['Used', camTotalUsed],
                            ['Available', camAvailable],
                        ],
                        type: 'pie',
                    },
                    tooltip: {
                        contents(d, defaultTitleFormat, defaultValueFormat, color) {
                            let info = '';
                            if (d[0].id === 'Available') {
                                const timeRemaining =
                                    d[0].value / self.camUsagePerSecond[self.cameraUsage.camera];
                                info = `${d[0].name} (${formatBytes(d[0].value)}) - ${formatSeconds(
                                    timeRemaining
                                )} remaining`;
                            } else {
                                info = `${d[0].name} (${formatBytes(d[0].value)})`;
                            }
                            d[0].name = info;
                            return this.getTooltipContent(
                                d,
                                defaultTitleFormat,
                                defaultValueFormat,
                                color
                            );
                        },
                    },
                    color: {
                        pattern: ['#ff6600', '#009933'],
                    },
                });
            };

            try {
                // self.charts.siteChart = generateSiteChart();
                // self.charts.siteChart.focus(self.currentChartView);
                // self.charts.usageChart = generateUsageChart();
                // self.charts.usageChart.focus(self.currentRouteView);
                self.charts.usageBarChart = generateBarChart();
                // self.charts.usagePerRouteChart = generateRouteChart();
                // self.charts.usagePerCamChart = generatePerCamChart();
                // self.charts.availablePerCamChart = generateAvailableChart();
                self.prepareCamData();
                processStorageRouteData();
            } catch (e) {
                console.warn('Error generating charts?', e);
            }
        };

        this.loadSite = function loadFullSite(siteId) {
            this.$http.get(`/api/sites/${siteId}`).then((site) => {
                self.currentSite = self.formatSiteZones(site.data);
                const hasSelectedCameras = self.params.cameras && self.params.cameras.length > 0;

                if (self.currentSite && self.currentSite.zones) {
                    self.currentSite.zones = _.filter(
                        self.currentSite.zones,
                        (zone) => !zone.disabled
                    );
                }

                if (hasSelectedCameras) {
                    self.params.cameras.forEach((selectedZone) => {
                        const paramZone = _.find(
                            self.currentSite.zones,
                            (o) => o._id === selectedZone
                        );
                        if (paramZone) {
                            self.cameras.push(paramZone);
                        }
                    });
                }
                self.prepareData();

                // load videos
                self.$http
                    .get('api/videos/', {
                        params: {
                            params: JSON.stringify([
                                {
                                    field: 'site',
                                    type: 'objectId',
                                    value: [self.currentSite._id],
                                    operator: '$in',
                                },
                            ]),
                        },
                    })
                    .then((resp) => {
                        self.siteVideos = resp.data;
                        self.siteVideos.forEach((vid) => {
                            if (vid.siteAlias) {
                                const videoSite = _.find(
                                    self.availableSites,
                                    (o) => vid.site === o._id
                                );
                                vid.siteAlias = videoSite.alias;
                            }
                            self.openFlags.videos[vid.taskId] = false;
                        });
                    });

                // load tasks
                if (self.Auth.hasPrivilegeSync('secuvue.task.index')) {
                    const cleanUpFunc = self.$rootScope.$on('taskUpdate', self.newTaskCallback);
                    self.cleanUpFuncions.push(cleanUpFunc);
                    self.tasksService
                        .getTasks()
                        .then((recvTasks) => {
                            self.tasks = recvTasks.filter(
                                (o) =>
                                    o.user === self.currentUser._id &&
                                    o.site === self.currentSite._id &&
                                    _.includes(self.taskTypes, o.type)
                            );
                            if (self.Auth.hasPrivilegeSync('secuvue.task.createTask')) {
                                self.getUsageData();
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                        });
                }
            });
        };

        this.checkHikvisionDLSupport = function hikDLCheck(units) {
            self.supportsHikvisionDL = false;
            let unitSupportsHikvisionDL = false;
            let siteHasHikvisionDevice = false;

            // Check firmware version of all units in site
            const supportMap = [];
            units.forEach((unit) => {
                const supportsHikvision = GeneralUtils.checkVersionNumber(unit.version, '1.3.10.3');
                supportMap.push(supportsHikvision);
            });
            supportMap.forEach((flag) => {
                if (flag === true) {
                    unitSupportsHikvisionDL = true;
                }
            });

            // Check for metadata flag in site's zones
            self.currentSite?.zones?.forEach((zone) => {
                if (zone?.cameraMetadata?.customFields?.length !== 0) {
                    zone?.cameraMetadata?.customFields?.forEach((metadata) => {
                        if (metadata?.key?.toUpperCase() === 'NVR' || metadata?.key?.toUpperCase() === 'XVR') {
                            siteHasHikvisionDevice = true;
                        }
                    });
                }
            });

            if (unitSupportsHikvisionDL && siteHasHikvisionDevice) {
                self.supportsHikvisionDL = true;
            }
        };

        this.$scope.$on('$destroy', () => {
            socket.unsyncUpdates('site');
            socket.unsyncUpdates('camera');
            if (self.currentRooms.length > 0) {
                self.currentRooms.forEach((room) => {
                    socket.leaveRoom(room);
                });
            }
            for (const chart in self.charts) {
                self.charts[chart].destroy();
            }
            for (const i in self.cleanUpFuncions) {
                self.cleanUpFuncions[i]();
            }
        });
    }

    $onInit() {
        const self = this;

        self.isModal = this.isModal;
        self.currentUser = self.getCurrentUser();

        if (self.params.cameras) {
            self.incidentDate = new Date(self.params.date);
            self.openFlags.filter = true;
        } else {
            self.cameras = [];
            self.incidentDate = new Date();
            self.openFlags.filter = false;
        }
        if (self.params.openTasks) {
            for (let i = 0; i < self.params.openTasks.length; i++) {
                self.openFlags.progress = true;
                self.openFlags.tasks[self.params.openTasks[i]] = true;
            }
        }

        // Uses 10sec chunks
        self.maxLength = 360;
        self.minuteAfter = 2;
        self.minuteBefore = self.maxLength - 3;
        self.incidentTime = new Date(self.incidentDate);
        self.minuteBeforeMax = self.maxLength - self.minuteAfter;
        self.totalLength = self.minuteBeforeMax - self.minuteBefore + self.minuteAfter;

        self.sliderChanged();

        [self.selectedEncoding] = self.availableEncoding;
        self.scaleDown = 0.5;
        self.camUsagePerSecond = {};

        self.calculateChartData = function processChartData() {
            self.siteChartData = {
                Used: {},
                Available: {},
                Reserved: {},
                Capacity: {},
                EstimatedRemainingSeconds: {},
            };
            self.extData.siteSummary.totalUsed = 0;
            self.extData.siteSummary.totalAvailable = 0;
            self.extData.siteSummary.totalCapacity = 0;
            self.extData.siteSummary.totalReserved = 0;
            self.extData.siteSummary.routes = [];
            let used = 0;
            let available = 0;
            let reserved = 0;
            let capacity = 0;
            let usedPerCam = 0;
            let vidCountPerCam = 0;
            let chunkSize = 0;
            let totalBytesPerSecond = 0;
            if (self.siteUsageData) {
                try {
                    self.siteUsageData.diskCapacity.forEach((route) => {
                        self.extData.siteSummary.routes.push(route.key);
                        self.siteChartData.Used[route.key] = 0;
                        self.siteChartData.Available[route.key] = 0;
                        self.siteChartData.Reserved[route.key] = 0;
                    });
                    self.siteUsageData.cameraUsage.forEach((camDoc) => {
                        const cam = camDoc.key;
                        usedPerCam = 0;
                        vidCountPerCam = 0;
                        camDoc.value.forEach((routeDoc) => {
                            const route = routeDoc.key;
                            if (
                                routeDoc.value &&
                                routeDoc.value.size !== undefined &&
                                routeDoc.value.count !== undefined
                            ) {
                                used += routeDoc.value.size;
                                usedPerCam += routeDoc.value.size;
                                vidCountPerCam += routeDoc.value.count;
                                self.siteChartData.Used[route] += routeDoc.value.size;
                                self.extData.siteSummary.totalUsed += routeDoc.value.size;
                            } else if (
                                routeDoc.size !== undefined &&
                                routeDoc.count !== undefined
                            ) {
                                used += routeDoc.size;
                                usedPerCam += routeDoc.size;
                                vidCountPerCam += routeDoc.count;
                                self.siteChartData.Used[route] += routeDoc.size;
                                self.extData.siteSummary.totalUsed += routeDoc.size;
                            }
                        });
                        chunkSize = 10; // TODO make sure that chunksize is a good default
                        self.camUsagePerSecond[cam] =
                            usedPerCam / (vidCountPerCam * chunkSize) || 0;
                        if (self.camUsagePerSecond[cam] === Infinity)
                            self.camUsagePerSecond[cam] = 0;
                        totalBytesPerSecond += self.camUsagePerSecond[cam];
                    });

                    self.siteUsageData.diskCapacity.forEach((routeDoc) => {
                        const route = routeDoc.key;
                        const sizeQuotaDoc = _.find(
                            self.siteUsageData.diskSizeQuota,
                            (o) => o.key === route
                        );
                        if (sizeQuotaDoc) {
                            reserved += routeDoc.value - sizeQuotaDoc.value;
                            self.siteChartData.Reserved[route] +=
                                routeDoc.value - sizeQuotaDoc.value;
                            capacity += routeDoc.value;
                            self.siteChartData.Capacity[route] = routeDoc.value;
                            self.siteChartData.Available[route] =
                                routeDoc.value -
                                self.siteChartData.Reserved[route] -
                                self.siteChartData.Used[route];
                            self.siteChartData.EstimatedRemainingSeconds[route] =
                                self.siteChartData.Available[route] / totalBytesPerSecond;
                            self.extData.siteSummary.totalAvailable +=
                                self.siteChartData.Available[route];
                            self.extData.siteSummary.totalCapacity +=
                                self.siteChartData.Capacity[route];
                            self.extData.siteSummary.totalReserved +=
                                self.siteChartData.Reserved[route];
                        }
                    });

                    self.siteUsageData.oldestPerCamera.forEach((camDoc) => {
                        const camId = camDoc.key;
                        const zone = _.find(
                            self.currentSite.zones,
                            (o) => o.camera === camId || `${o.unit}::${o.camera}` === camId
                        );
                        if (zone) {
                            if(camDoc.value) {
                                zone.oldest = self.moment.unix(camDoc.value).format('llll');
                                if (!self.extData.siteSummary.oldest) {
                                    self.extData.siteSummary.oldest = zone.oldest;
                                }
                                if (self.extData.siteSummary.oldest > zone.oldest) {
                                    self.extData.siteSummary.oldest = zone.oldest;
                                }
                            }
                            else
                                zone.oldest = 0;
                        }
                    });

                    self.siteUsageData.newestPerCamera.forEach((camDoc) => {
                        const camId = camDoc.key;
                        const zone = _.find(
                            self.currentSite.zones,
                            (o) => o.camera === camId || `${o.unit}::${o.camera}` === camId
                        );
                        if (zone) {
                            if(camDoc.value) {
                                zone.newest = self.moment.unix(camDoc.value).format('llll');
                                if (!self.extData.siteSummary.newest) {
                                    self.extData.siteSummary.newest = zone.newest;
                                }
                                if (self.extData.siteSummary.newest < zone.newest) {
                                    self.extData.siteSummary.newest = zone.newest;
                                }
                            }
                            else
                                zone.newest = 0;
                        }
                    });

                    available = capacity - used - reserved;

                    self.extData.siteSummary.totalAvailable =
                        self.extData.siteSummary.totalCapacity -
                        self.extData.siteSummary.totalUsed -
                        self.extData.siteSummary.totalReserved;

                    if (
                        !Number.isNaN(used) &&
                        !Number.isNaN(available) &&
                        !Number.isNaN(reserved)
                    ) {
                        self.chartData = {
                            Used: used,
                            Available: available,
                            Reserved: reserved,
                            EstimatedRemainingSeconds: available / totalBytesPerSecond,
                        };
                    } else {
                        self.chartData = {
                            Used: 0,
                            Available: 0,
                            Reserved: 0,
                            EstimatedRemainingSeconds: 0,
                        };
                    }
                } catch (error) {
                    console.error('chart data calc error', error);
                }
            }
        };

        self.updateCharts = function updateCharts() {
            self.calculateChartData();
            if (
                !self.charts ||
                !self.charts.siteChart ||
                !self.charts.usageChart ||
                !self.charts.usagePerRouteChart
            ) {
                self.renderCharts();
            }
            if (self.charts) {
                try {
                    if (self.charts.siteChart) {
                        self.charts.siteChart.load({
                            columns: [
                                ['Used', self.chartData.Used],
                                ['Available', self.chartData.Available],
                                ['Reserved', self.chartData.Reserved],
                            ],
                        });
                    }
                    if (self.charts.usageChart) {
                        self.prepareRootData();
                    }
                    if (self.charts.usagePerRouteChart) {
                        self.prepareCamData();
                    }
                } catch (error) {
                    console.error('updateChart error:', error);
                }
            }
        };

        self.newTaskCallback = function taskCallback(event, eventObj) {
            const isSameUser = eventObj.item.user === self.currentUser._id;
            const isSameSite = eventObj.item.site === self.currentSite._id;

            switch (eventObj.event) {
                case 'created':
                case 'updated':
                    if (isSameUser && isSameSite) {
                        if (_.includes(self.taskTypes, eventObj.item.type)) {
                            self.tasks = eventObj.array.filter(
                                (o) =>
                                    o.user === self.currentUser._id &&
                                    o.site === self.currentSite._id &&
                                    _.includes(self.taskTypes, o.type)
                            );
                        }
                    }
                    break;
                case 'deleted':
                    self.tasks = eventObj.array.filter(
                        (o) =>
                            o.user === self.currentUser._id &&
                            o.site === self.currentSite._id &&
                            _.includes(self.taskTypes, o.type)
                    );
                    break;
                default: {
                    const index = eventObj.array.indexOf(eventObj.item);
                    if (index !== -1) {
                        eventObj.array.splice(index, 1);
                    }
                    self.tasks = eventObj.array.filter(
                        (o) => o.user === self.currentUser._id && o.site === self.currentSite._id
                    );
                }
            }
        };

        self.prepareData = function calculateData() {
            self.getRefreshOffset();
            const combinedData = {
                cameraSizeQuota: [],
                cameraUsage: [],
                diskCapacity: [],
                diskSizeQuota: [],
                newestPerCamera: [],
                oldestPerCamera: [],
                rootUsed: [],
            };
            self.checkHikvisionDLSupport(self.currentSite.units);
            self.currentSite.units.forEach((unit) => {
                if (unit.usageData && unit.usageData.data) {
                    try {
                        const { data } = unit.usageData;
                        // NOTE: CameraSizeQuota is NEVER provided by unit
                        data.cameraSizeQuota?.forEach((cam) => {
                            if (cam.value.id) {
                                combinedData.cameraSizeQuota.push({
                                    key: `${unit._id}::${cam.value.id}`,
                                    value: cam.value.value,
                                });
                            } else {
                                combinedData.cameraSizeQuota.push(cam);
                            }
                        });
                        data.newestPerCamera.forEach((cam) => {
                            if (cam.value.id) {
                                combinedData.newestPerCamera.push({
                                    key: `${unit._id}::${cam.value.id}`,
                                    value: cam.value.timestamp,
                                });
                            } else {
                                combinedData.newestPerCamera.push(cam);
                            }
                        });
                        data.oldestPerCamera.forEach((cam) => {
                            if (cam.value.id) {
                                combinedData.oldestPerCamera.push({
                                    key: `${unit._id}::${cam.value.id}`,
                                    value: cam.value.timestamp,
                                });
                            } else {
                                combinedData.oldestPerCamera.push(cam);
                            }
                        });
                        data.diskCapacity.forEach((route) => {
                            // NOTE: Exception for data pollution
                            if (route.value.id) {
                                combinedData.diskCapacity.push({
                                    key: `${unit.alias} - ${route.value.id}`,
                                    value: route.value.size,
                                });
                            } else {
                                combinedData.diskCapacity.push({
                                    key: `${unit.alias} - ${route.key}`,
                                    value: route.value,
                                });
                            }
                        });
                        data.diskSizeQuota.forEach((route) => {
                            if (route.value.id) {
                                combinedData.diskSizeQuota.push({
                                    key: `${unit.alias} - ${route.value.id}`,
                                    value: route.value.size,
                                });
                            } else {
                                combinedData.diskSizeQuota.push({
                                    key: `${unit.alias} - ${route.key}`,
                                    value: route.value,
                                });
                            }
                        });
                        data.rootUsed.forEach((route) => {
                            if (route.value.id) {
                                combinedData.rootUsed.push({
                                    key: `${unit.alias} - ${route.value.id}`,
                                    value: route.value.size,
                                });
                            } else {
                                combinedData.rootUsed.push({
                                    key: `${unit.alias} - ${route.key}`,
                                    value: route.value,
                                });
                            }
                        });
                        data.cameraUsage.forEach((cam) => {
                            const newCam = {
                                value: [],
                            };
                            cam.value.forEach((route) => {
                                switch (route.key) {
                                    case 'roots':
                                        route.value.forEach((rootItem) => {
                                            newCam.value.push({
                                                key: `${unit.alias} - ${rootItem.id}`,
                                                value: {
                                                    size: rootItem.size,
                                                    count: rootItem.count,
                                                },
                                            });
                                        });
                                        break;
                                    case 'id':
                                        newCam.key = `${unit._id}::${route.value}`;
                                        break;
                                    default:
                                        // Is from getUsageData
                                        newCam.key = `${cam.key}`;
                                        route.key = `${unit.alias} - ${route.key}`;
                                        newCam.value.push(route);
                                }
                            });
                            combinedData.cameraUsage.push(newCam);
                        });
                    } catch (error) {
                        console.warn('Data preparation error:', error);
                    }
                }

            });
            if (combinedData.diskCapacity) {
                self.siteUsageData = combinedData;
                self.updateCharts();
            }
        };

        self.Auth.hasPrivilege('secuvue.site.basicIndex').then((has) => {
            if (has) {
                self.$http.get('/api/sites/basic').then((response) => {
                    self.availableSites = _.map(response.data, self.formatSiteZones);
                    self.socket.joinRoom(`${self.currentAccount}:*:basic:sites`);
                    self.currentRooms.push(`${self.currentAccount}:*:basic:sites`);

                    self.socket.syncUpdates('site', self.availableSites, (event, item) => {
                        item = self.formatSiteZones(item);
                        const index = _.findIndex(
                            self.availableSites,
                            (site) => site._id === item._id
                        );
                        if (index >= 0) {
                            if (event === 'deleted') {
                                self.availableSites.splice(index, 1);
                            } else {
                                self.availableSites[index] = item;
                            }
                        } else if (event !== 'deleted') {
                            self.availableSites.push(item);
                        }

                        if (self.currentSite && item._id === self.currentSite._id) {
                            if (event === 'deleted') {
                                self.currentSite = undefined;
                            } else {
                                this.$http
                                    .get(`/api/sites/${self.currentSite._id}`)
                                    .then((site) => {
                                        self.currentSite = self.formatSiteZones(site.data);
                                        if (self.currentSite && self.currentSite.zones) {
                                            self.currentSite.zones = _.filter(
                                                self.currentSite.zones,
                                                (zone) => !zone.disabled
                                            );
                                        }
                                        self.prepareData();
                                    });
                            }
                        }
                    });

                    if (self.params.id) {
                        self.loadSite(self.params.id);
                    }
                });
            }
        });
    }

    cancelTask(task) {
        task.status = 'cancel';
        this.$http.post(`api/tasks/cancelTask`, task).then((response) => {
            if (response.data) {
                this.toastr.info('Task cancelled', { preventOpenDuplicates: true });
            }
        });
    }

    deleteTask(task) {
        this.$http.delete(`api/tasks/deleteTask/${task._id}`).then((response) => {
            if (response.data) {
                this.toastr.info('Task deleted', { preventOpenDuplicates: true });
            }
        });
    }

    retryTask(task) {
        this.$http.post(`api/tasks/retryTask`, task).then((response) => {
            if (response.data) {
                this.toastr.info('Task retry issued', { preventOpenDuplicates: true });
            }
        });
    }

    selected($item, $model) {
        const self = this;

        self.tasks = [];
        self.siteChartData = {};
        if (self.charts && self.charts.siteChart) {
            self.charts.siteChart.unload();
        }
        if (self.charts && self.charts.usageChart) {
            self.charts.usageChart.unload();
        }
        if (self.charts && self.charts.usagePerRouteChart) {
            self.charts.usagePerRouteChart.unload();
        }
        self.$state
            .go('.', { id: $item._id, openTasks: true })
            .then(() => {
                const filteredRooms = _.filter(self.currentRooms, (room) => /:cameras$/.test(room));
                if (filteredRooms.length > 0) {
                    filteredRooms.forEach((room) => {
                        self.socket.leaveRoom(room);
                    });
                }
                const filteredVideoRooms = _.filter(self.currentRooms, (room) =>
                    /:videos$/.test(room)
                );
                if (filteredVideoRooms.length > 0) {
                    filteredVideoRooms.forEach((room) => {
                        self.socket.leaveRoom(room);
                    });
                }
                self.socket.unsyncUpdates('video');
                self.socket.unsyncUpdates('camera');
                self.$http
                    .get('api/videos/', {
                        params: {
                            params: JSON.stringify([
                                {
                                    field: 'site',
                                    type: 'objectId',
                                    value: [$item._id],
                                    operator: '$in',
                                },
                            ]),
                        },
                    })
                    .then((resp) => {
                        self.siteVideos = resp.data;
                        self.siteVideos.forEach((vid) => {
                            if (!vid.siteAlias) {
                                const site = _.find(self.availableSites, (o) => vid.site === o._id);
                                vid.siteAlias = site.alias;
                            }
                            self.openFlags.videos[vid.taskId] = false;
                        });
                    });

                self.socket.joinRoom(`${self.currentAccount}:${$item._id}:*:videos`);
                self.socket.syncUpdates('video', self.siteVideos, (event, item) => {
                    if (item.site === self.currentSite._id) {
                        if (event === 'created') {
                            if (!item.siteAlias) {
                                const site = _.find(
                                    self.availableSites,
                                    (o) => item.site === o._id
                                );
                                item.siteAlias = site.alias;
                            }
                            self.siteVideos.push(item);
                        } else if (event === 'deleted') {
                            _.remove(self.siteVideos, (o) => o._id === item._id);
                        } else {
                            const index = _.findIndex(self.siteVideos, (o) => o._id === item._id);
                            if (index >= 0) {
                                if (!item.siteAlias) {
                                    const site = _.find(
                                        self.availableSites,
                                        (o) => item.site === o._id
                                    );
                                    item.siteAlias = site.alias;
                                }
                                self.siteVideos.splice(index, 1, item);
                            }
                        }
                    }
                });

                self.tasks = self.tasksService
                    .getTasksSync()
                    .filter(
                        (o) =>
                            o.user === self.currentUser._id &&
                            o.site === self.currentSite._id &&
                            _.includes(self.taskTypes, o.type)
                    );
                const cleanUpFunc = self.$rootScope.$on('taskUpdate', self.newTaskCallback);
                self.cleanUpFuncions.push(cleanUpFunc);
                self.getUsageData();
                if (self.currentSite && self.currentSite.zones) {
                    self.currentSite.zones = _.filter(
                        self.currentSite.zones,
                        (zone) => !zone.disabled
                    );
                    self.prepareData();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getUsageData(unit) {
        const self = this;

        const issueTask = function (taskedUnit) {
            const getUsageDataTask = {
                alias: 'GetUsageData',
                user: self.Auth.getCurrentUserSync()._id,
                site: self.currentSite._id,
                unit: taskedUnit._id,
                type: 'getUsageData',
                status: 'starting',
                processes: [],
                taskOptions: {},
                taskResults: {},
                createdAt: Date.now(),
            };
            if (self.Auth.hasPrivilegeSync('secuvue.task.createTask')) {
                self.$http
                    .post(`api/tasks/createTask`, getUsageDataTask)
                    .then(() => {})
                    .catch((err) => {
                        console.warn('getUsageData task creation error:', err);
                    });
            }
        };

        if (unit) {
            issueTask(unit);
            self.checkHikvisionDLSupport([unit]);
        } else if (self.currentSite.units && self.currentSite.units.length > 0) {
            self.currentSite.units.forEach((eachUnit) => {
                issueTask(eachUnit);
            });
            self.checkHikvisionDLSupport(self.currentSite.units);
        } else {
            console.error('No units in site!!');
        }
    }

    sliderChanged() {
        const self = this;
        const beforeDiff = self.minuteBeforeMax - self.minuteBefore;
        self.minuteBeforeMax = self.maxLength - self.minuteAfter;
        self.minuteBefore = self.minuteBeforeMax - beforeDiff;
        if (self.minuteBefore > self.minuteBeforeMax) {
            self.minuteBefore = self.minuteBeforeMax;
        }
        self.minuteAfterMax = self.maxLength - (self.minuteBeforeMax - self.minuteBefore);
        self.incidentTime.setFullYear(self.incidentDate.getFullYear());
        self.incidentTime.setMonth(self.incidentDate.getMonth());
        self.incidentTime.setDate(self.incidentDate.getDate());
        self.fromTime = new self.moment(self.incidentTime);
        self.fromTime = self.fromTime
            .subtract((self.minuteBeforeMax - self.minuteBefore) * 10, 'seconds')
            .toDate();
        self.toTime = new self.moment(self.incidentTime);
        self.toTime = self.toTime.add(self.minuteAfter * 10, 'seconds').toDate();

        self.beforeLength = self.minuteBeforeMax - self.minuteBefore;
        self.totalLength = self.minuteBeforeMax - self.minuteBefore + self.minuteAfter;
    }

    createTask() {
        const self = this;
        self.cameras.forEach((camera) => {
            const taskCamera = _.pick(camera, ['camera', '_id', 'alias', 'unit']);
            if (taskCamera.camera.startsWith(taskCamera.unit)) {
                taskCamera.camera = taskCamera.camera.slice(taskCamera.unit.length + 2);
            }
            self.currentTask = {
                alias: `Video from ${self.fromTime.toLocaleString()} to ${self.toTime.toLocaleString()}`,
                user: self.Auth.getCurrentUserSync()._id,
                site: self.currentSite._id,
                unit: camera.unit,
                type: 'retrieveVideo',
                status: 'starting',
                processes: [
                    {
                        type: 'Retrieving Video',
                        progress: 0,
                    },
                ],
                taskOptions: {
                    from: +self.moment.utc(self.fromTime),
                    to: +self.moment.utc(self.toTime),
                    // encoding: self.selectedEncoding, // TODO options?
                    // scale: self.scaleDown, // TODO options?
                    cameras: [taskCamera],
                    filter: self.onlyMotion ? '^.*[mM].(mp4|mkv)$' : undefined,
                },
                taskResults: {},
                createdAt: Date.now(),
            };
            self.$http
                .post(`api/tasks/createTask`, self.currentTask)
                .then((response) => {
                    if (response.data) {
                        self.openFlags.tasks[response.data._id] = true;
                        self.openFlags.progress = true;
                    }
                })
                .catch((err) => {
                    console.log('Error:', err.data.err);
                    if (err.data && err.data.err) {
                        if (err.data.err === 'UnitUnbounded') {
                            self.toastr.error('Zone is not bounded to a unit', {});
                        } else if (err.data.err === 'SiteNotFound') {
                            self.toastr.error('Site not found', {});
                        }
                    } else {
                        self.toastr.error('Error creating task', {});
                    }
                });
        });
    }

    getHikvisionInfo(camera) {
        const ipRegex =
            /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/g;
        const hikvisionChannelRegex = /hannels\/(\d+)/;

        const device = {
            address: '127.0.0.1',
            username: 'admin',
            password: 'admin',
            channel: 1,
            port: 8000,
            _id: camera._id,
            unit: camera.unit,
            alias: camera.alias,
            camera: camera.camera,
        };
        try {
            [, device.channel] = camera.url.match(hikvisionChannelRegex);
            if (parseInt(device.channel, 10) >= 100) {
                device.channel = parseInt(device.channel.slice(0, -2), 10);
            }
        } catch (rtspE) {
            console.log('RTSP Channel regex failure', rtspE);
        }

        if (camera.url) {
            [device.address] = camera.url.match(ipRegex);
        }
        if (camera.username) device.username = camera.username;
        if (camera.password) device.password = camera.password;
        if (camera.port) device.port = camera.port;

        return device;
    }

    createHikvisionTask() {
        const self = this;
        const timeZoneOffset = new Date().getTimezoneOffset() * -1;
        self.cameras.forEach((camera) => {
            const taskCamera = _.pick(camera, [
                '_id',
                'camera',
                'alias',
                'unit',
                'url',
                'username',
                'password',
                'camtype',
            ]);
            if (taskCamera.camera.startsWith(taskCamera.unit)) {
                taskCamera.camera = taskCamera.camera.slice(taskCamera.unit.length + 2);
            }
            const hikvisionDevice = self.getHikvisionInfo(taskCamera);
            self.currentTask = {
                alias: `Hikvision Video from ${self.fromTime.toLocaleString()} to ${self.toTime.toLocaleString()}`,
                user: self.Auth.getCurrentUserSync()._id,
                site: self.currentSite._id,
                unit: taskCamera.unit,
                type: 'hikvisionVideoRetrieval',
                status: 'starting',
                processes: [
                    {
                        type: 'Download from Device',
                        progress: 0,
                    },
                    {
                        type: 'Retrieving Video',
                        progress: 0,
                    },
                ],
                taskOptions: {
                    from: +self.moment.utc(self.fromTime),
                    to: +self.moment.utc(self.toTime),
                    tzHours: Math.floor(timeZoneOffset / 60),
                    tzMinutes: timeZoneOffset % 60,
                    cameras: [hikvisionDevice],
                },
                taskResults: {},
                createdAt: Date.now(),
            };

            self.$http
                .post(`api/tasks/createTask`, self.currentTask)
                .then((response) => {
                    if (response.data) {
                        self.openFlags.tasks[response.data._id] = true;
                        self.openFlags.progress = true;
                    }
                })
                .catch((err) => {
                    console.log('Error:', err.data.err);
                    if (err.data && err.data.err) {
                        if (err.data.err === 'UnitUnbounded') {
                            self.toastr.error('Zone is not bounded to a unit', {});
                        } else if (err.data.err === 'SiteNotFound') {
                            self.toastr.error('Site not found', {});
                        }
                    } else {
                        self.toastr.error('Error creating task', {});
                    }
                });
        });
    }

    closeModal() {
        if (this.isModal) {
            this.modalInstance.close('cancel');
        }
    }

    selectedCameraUsage() {
        const self = this;
        self.usagePerCamChartShow = true;

        if (self.selectedZone) {
            const camData = [];

            let camTotalUsed = 0;
            const zoneUsage = _.find(
                self.siteUsageData.cameraUsage,
                (o) =>
                    o.key === self.selectedZone.camera ||
                    o.key === `${self.selectedZone.unit}::${self.selectedZone.camera}`
            );
            zoneUsage?.value.forEach((routeDoc) => {
                if (routeDoc && routeDoc.value && routeDoc.value.size && routeDoc.value.count) {
                    camData.push([routeDoc.key, routeDoc.value.size]);
                    camTotalUsed += routeDoc.value.size;
                }
            });
            self.selectedZone.camTotal = camTotalUsed;
            self.charts.usagePerCamChart.load({
                columns: camData,
            });

            let camAvailable = 0;
            const cameraSizeQuotaDoc = _.find(
                self.siteUsageData.cameraSizeQuota,
                (o) =>
                    o.key === self.selectedZone.camera ||
                    o.key === `${self.selectedZone.unit}::${self.selectedZone.camera}`
            );
            if (cameraSizeQuotaDoc && cameraSizeQuotaDoc.value !== undefined) {
                if (cameraSizeQuotaDoc.value > 0) {
                    camAvailable = cameraSizeQuotaDoc.value - camTotalUsed;
                }
            }
            self.charts.availablePerCamChart.load({
                columns: [
                    ['Used', camTotalUsed],
                    ['Available', camAvailable],
                ],
            });

            self.usagePerCamChartShow = !!camTotalUsed;
        } else {
            self.usagePerCamChartShow = false;
            self.charts.usagePerCamChart.unload();
        }
    }

    getDownloadName(task, camera) {
        const self = this;
        const fromTime = self.moment(task.taskOptions.from).format('Y-MM-DD hh-mm A');
        const toTime = self.moment(task.taskOptions.to).format('Y-MM-DD hh-mm A');
        return `${self.currentSite.alias} ${camera.alias} ${fromTime} to ${toTime}.mp4`;
    }

    getVideoName(vid) {
        const self = this;
        const site = _.find(self.availableSites, (o) => o._id === vid.site);
        if (site) {
            const zone = _.find(
                site.zones,
                (o) => o.camera === vid.camera || o.camera === `${vid.unit}::${vid.camera}`
            );
            if (zone) {
                return `${site.alias} ${zone.alias} ${vid.taskAlias.slice(vid.cameraAlias.length)}`;
            }
            return vid.taskAlias;
        }
        return vid.taskAlias;
    }

    getSignedUrl(vid) {
        return `/api/videos/${vid._id}/getS3VideoSigned`;
    }

    makeVideoUrl(task, camera) {
        const self = this;
        if (task.status === 'done') {
            return `/api/tasks/${task._id}/retrieveVideo/${window.encodeURIComponent(
                camera.camera
            )}/${self.currentAccount}`;
        }
        return '';
    }

    getRefreshOffset() {
        const self = this;
        let baseOffset = 31 + 26;
        baseOffset += 46 * self.currentSite.units.length;
        self.refreshDropdownOffset = {
            position: 'relative',
            top: `-${baseOffset / 2}px`,
        };
    }

    formatSiteZones(site) {
        // Flatten unit cameras into same array
        let allUnitCameras = [];
        site.units.forEach((unit) => {
            unit.cameras.forEach((cam) => {
                cam.unit = unit._id;
                cam.unitDown = unit.down;
                cam.camId = cam._id;
                delete cam.alias; // Always prefer zone's alias over camera's alias
                delete cam._id; // Moved to prevent zone _id override
            });
            allUnitCameras = allUnitCameras.concat(unit.cameras);
        });

        // Merge zones and cameras
        const mergedZones = _.merge(
            _.keyBy(site.zones, 'camera'),
            _.keyBy(allUnitCameras, 'camId')
        );
        site.zones = Object.values(mergedZones);
        site.zones.forEach((zone) => {
            if (zone?.profiles?.length > 0) zone.url = zone.profiles[0].streamuri;
        });

        return site;
    }

    testValidFilter() {
        const self = this;
        const now = self.moment();
        self.filterIssues = [];
        let valid = true;
        if (now < self.moment(self.fromTime)) {
            self.filterIssues.push('Selected time is invalid.');
            valid = false;
        }
        if (self.cameras && self.cameras.length > 0) {
            if (_.some(self.cameras, 'down')) {
                self.filterIssues.push('Please note that some cameras are down.');
            }
            if (_.some(self.cameras, 'unitDown')) {
                self.filterIssues.push('Please note that the unit is currently down.');
            }
        } else {
            self.filterIssues.push('Please select a camera.');
            valid = false;
        }
        // TODO: Remember we should add this back in at some point
        // if (!self.supportsHikvisionDL) {
        //     self.filterIssues.push('Please note unit does not support Hikvision DL.');
        // }
        return valid;
    }

    doLog() {
        console.log(this);
    }
}

export default angular
    .module('fileManager')
    .filter(
        'trustUrl',
        /* @ngInject */ ($sce) =>
            function trustUrl(recordingUrl) {
                if (typeof recordingUrl === 'string') {
                    return $sce.trustAsResourceUrl(recordingUrl);
                }
                return null;
            }
    )
    .filter(
        'dataSize',
        () =>
            function computeDataSize(input) {
                if (input < 1024) {
                    return `${Math.round((input / 1024) * 1000) / 1000} kB`;
                }
                if (input / 1024 < 1024) {
                    return `${Math.round((input / 1024) * 1000) / 1000} kB`;
                }
                if (input / 1024 / 1024 < 1024) {
                    return `${Math.round((input / 1024 / 1024) * 1000) / 1000} MB`;
                }
                if (input / 1024 / 1024 / 1024 < 1024) {
                    return `${Math.round((input / 1024 / 1024 / 1024) * 1000) / 1000} GB`;
                }
                if (input / 1024 / 1024 / 1024 / 1024 < 1024) {
                    return `${Math.round((input / 1024 / 1024 / 1024) * 1000) / 1000} TB`;
                }
                return 'N/A bytes';
            }
    )
    .component('fileManager', {
        template: require('./fileManager.html'),
        controller: FileManagerComponent,
        controllerAs: '$ctrl',
    }).name;
