'use strict';

import _ from 'lodash-es';
import exceljs from "exceljs";
import FileSaver from 'file-saver';

export class SiteOverviewComponent {
    $http;
    $state;
    moment;
    toastr;
    Auth;
    sites: [];
    cameras: [];
    NgTableParams;
    $sce;
    $uibModal;

    /*@ngInject*/
    constructor($http, toastr, moment, NgTableParams, $state, $scope, Auth, $sce, $uibModal) {
        this.$http = $http;
        this.$state = $state;
        this.Auth = Auth;
        this.toastr = toastr;
        this.moment = moment;
        this.allAccounts = false;
        this.NgTableParams = NgTableParams;
        this.sites = [];
        this.$sce = $sce;
        this.$uibModal = $uibModal;
        this.filter = '';
        this.zones = [];
        this.units = [];
        this.isProcessing = false;
        this.filterUnconfigured = false;
    }

    toggleUnconfigured() {
        this.filterUnconfigured = !this.filterUnconfigured;
        this.tableParams.reload();
    }

    doLog() {
        console.log("DEBUG:", this);
    }

    $onDestroy() {

    }

    $onInit() {
        let self = this;
        self.selectedColumns = [
            //"Account",
            'Alias',
            'Enabled',
            'Unit Count',
            'Zone Count',
            'Units With Zones',
            'Units Enabled',
            'Units LS Active',
            'Units Online',
            'Zones Enabled',
            'Zones Active',
            'Zones VVSEC Enabled',
            'Zones Motion Trigger',
            'Zones Line Count',
            'Zones Line Triggers',
            'Zones Face Analytics',
            'Zones LPR Analytics',
            'Zones Heatmap Enabled',
            'Zones LS (24H)',
            'Zones LS (Motion Only)',
            'Zones LS (Scheduled)',
        ];

        // Columns with an X out of Y value
        self.zoneCountColumns = [
            'zoneEnabled',
            'zoneActive',
            'zoneVVSEC',
            'zoneMotionTrigger',
            'zoneLineCount',
            'zoneLineTrip',
            'zoneFaceAnalytics',
            'zoneLPRAnalytics',
            'zoneHeatmaps',
            'zoneLS24',
            'zoneLSMotion',
            'zoneLSScheduled'
        ];
        self.unitCountColumns = [
            'unitEnabled',
            'unitEmptyCount',
            'unitLSActive',
            'unitOnline'
        ];

        self.cols = [
            {
                title: "Alias",
                field: "alias",
                show: true,
                sortable: "alias",
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Enabled",
                field: "disabled",
                show: true,
                sortable: "disabled",
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Unit Count",
                field: "unitCount",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Units With Zones",
                field: "unitEmptyCount",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zone Count",
                field: "zoneCount",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Units Enabled",
                field: "unitEnabled",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Units LS Active",
                field: "unitLSActive",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Units Online",
                field: "unitOnline",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Enabled",
                field: "zoneEnabled",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Active",
                field: "zoneActive",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones VVSEC Enabled",
                field: "zoneVVSEC",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Motion Trigger",
                field: "zoneMotionTrigger",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Line Triggers",
                field: "zoneLineTrip",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Line Count",
                field: "zoneLineCount",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Face Analytics",
                field: "zoneFaceAnalytics",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones LPR Analytics",
                field: "zoneLPRAnalytics",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones Heatmap Enabled",
                field: "zoneHeatmaps",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones LS (24H)",
                field: "zoneLS24",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones LS (Motion Only)",
                field: "zoneLSMotion",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: "Zones LS (Scheduled)",
                field: "zoneLSScheduled",
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.tableParams = new self.NgTableParams({
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    alias: 'asc' // initial sorting
                }
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    if(params && params.sorting) {
                        order = params.sorting();
                        self.isProcessing = true;
                        return self.getSitesTable(params)
                            .then((response) => {
                                let data = response.data;
                                self.siteRefs = data.data;
                                self.total = data.total;
                                params.total(data.total);
                                // TODO: We need to call formatData on each unit and return only units
                                self.isProcessing = false;
                                return self.siteRefs;
                            });
                    }
                }
            });
        self.tableParams.reload();
    }

    getSitesTable(params) {
        let self = this;
        let order = params.sorting();
        return self.$http.get('/api/sites/overview', {
            params: {
                filter: self.filter.length ? self.filter : undefined,
                skip: (params.page() - 1) * params.count(),
                limit: params.count(),
                by: Object.keys(order)[0],
                order: order[Object.keys(order)[0]],
                onlyUnconfigured: self.filterUnconfigured ? true : undefined,
            }
        })
            .then(response => {
                return response;
            });
    }

    handleDisplay(self, col, site) {
        switch (col.field) {
            case "unitEnabled":
            case "unitLSActive":
            case "unitOnline":
                return `${site[col.field]}/${site.unitCount}`;
            case "unitEmptyCount":
                return `${site.unitCount - site[col.field]}/${site.unitCount}`;
            case "zoneEnabled":
            case "zoneActive":
            case "zoneVVSEC":
            case "zoneMotionTrigger":
            case "zoneLineCount":
            case "zoneLineTrip":
            case "zoneFaceAnalytics":
            case "zoneLPRAnalytics":
            case "zoneHeatmaps":
            case "zoneLS24":
            case "zoneLSMotion":
            case "zoneLSScheduled":
                return `${site[col.field]}/${site.zoneCount}`;
            case "alias":
                return self.$sce.trustAsHtml(`<div style="cursor:pointer;color:navy;" ng-click="$ctrl.moveToZoneOverview(site._id)">${site[col.field]}</div>`);
            case "disabled":
                let html = '';
                if(!site[col.field]) {
                    html += `<span class="fa-lg">`
                        + `<i class="fa fa-check-circle text-success"></i>`
                        + `</span>`;
                } else {
                    html += `<span class="fa-lg">`
                        + `<i class="fa fa-ban text-danger"></i>`
                        + `</span>`;
                }

                return self.$sce.trustAsHtml(html);
            default:
                return site[col.field];
        }
    }

    moveToZoneOverview(site) {
        this.$state.go("main.zoneOverview", {site})
            .catch(err => {
                console.log("ERROR:", err);
            });
    }

    download() {
        let self = this;
        self.isProcessing = true;
        let workbook = new exceljs.Workbook();
        let sheet = workbook.addWorksheet("Site Overview");
        sheet.pageSetup.horizontalCentered = true;
        sheet.pageSetup.verticalCentered = true;

        self.$http.get('/api/sites/overview', {
            params: {
                by: 'alias',
                order: 'asc',
            }
        })
            .then(response => {
                let allSites = response.data.data;
                let dataRows = [self.cols.map(o => {
                    return o.title;
                })];
                allSites.forEach(site => {
                    let rowEntry = [];
                    self.cols.forEach(obj => {
                        let key = obj.field;
                        if(_.includes(self.zoneCountColumns, key)) {
                            rowEntry.push(`${site[key]}/${site.zoneCount}`);
                        } else if(key === 'unitEmptyCount') {
                            rowEntry.push(`${site.unitCount - site[key]}/${site.unitCount}`);
                        } else if(_.includes(self.unitCountColumns, key)) {
                            rowEntry.push(`${site[key]}/${site.unitCount}`);
                        } else if(key === 'disabled') {
                            rowEntry.push(!site[key]);
                        } else {
                            rowEntry.push(site[key]);
                        }
                    });
                    dataRows.push(rowEntry);
                });
                let acc = self.Auth.getCurrentAccountSync();
                dataRows.unshift([`Date & Time: ${self.moment()
                    .format('DD-MM-YYYY HH:mm:ss')}`]);
                dataRows.unshift([`Account: ${acc.name}`]);
                dataRows.forEach(row => {
                    sheet.addRow(row);
                });
                sheet.columns.forEach(column => {
                    let dataMax = 0;
                    column.alignment = {vertical: "top", horizontal: 'center'};
                    column.values.forEach(value => {
                        if(value && value.length) {
                            let columnLength = value.length;
                            if(columnLength > dataMax) {
                                dataMax = columnLength + 1;
                            }
                        }
                    });
                    column.width = dataMax < 10 ? 10 : dataMax;
                });
                workbook.xlsx.writeBuffer()
                    .then(buffer => {
                        let timestamp = self.moment().format('DD_MM_YYYY_HH:mm:ss');
                        FileSaver.saveAs(new Blob([buffer]), `${acc.name}_Site_Overview_${timestamp}.xlsx`);
                        self.isProcessing = false;
                    })
                    .catch(err => {
                        self.toastr.error(`Failed to export`, {preventOpenDuplicates: true});
                        console.log("Error while writing buffer", err);
                        self.isProcessing = false;
                    });
            });
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }
}

export default angular.module('cameraViewerApp.siteOverview')
    .component('siteOverview', {
        template: require('./siteOverview.html'),
        controller: SiteOverviewComponent,
        controllerAs: '$ctrl'
    })
    .name;
