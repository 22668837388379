import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import moment from 'moment';
import routes from './maintenance.routes';

export default angular.module('cameraViewerApp.maintenance', [uiRouter, 'angularMoment'])
    .config(routes)
    .name;

require('./maintenance.component');
