import _ from 'lodash-es';
import angular from 'angular';
import exceljs from 'exceljs';
import FileSaver from 'file-saver';
import ColumnData from './columnData';
import OverviewProcessing from './processing';

export class ZoneOverviewComponent {
    $http;
    $state;
    moment;
    toastr;
    Auth;
    sites; // : []
    cameras; // : []
    currentSite = undefined;
    NgTableParams;
    $sce;
    $filter;
    $uibModal;

    /* @ngInject */
    constructor($http, $document, toastr, moment, NgTableParams, $state, $scope, Auth, appConfig, $sce, $uibModal, $filter) {
        this.$filter = $filter;
        this.$document = $document;
        this.$http = $http;
        this.$state = $state;
        this.Auth = Auth;
        this.toastr = toastr;
        this.moment = moment;
        this.NgTableParams = NgTableParams;
        this.$sce = $sce;
        this.$uibModal = $uibModal;
        this.currentRooms = [];
        this.filter = '';
        this.firstTime = false;
        this.overviewMode = true;
        this.countryList = appConfig.default.countryList;
        this.includeCamMetadata = this.Auth.hasRoleSync('secuvue.SiteView.Settings.Zones.CameraMetadata');
        this.currentAccount = this.Auth.getCurrentAccountSync();

        this.zones = [];
        this.units = [];
        this.sites = [];
        this.availableSites = [];
    }

    $onDestroy() {

    }

    siteListener(item) {
        const self = this;
        // if(self.selectedSite && item._id == self.selectedSite._id) {
        //     self.selectedSite = item;
        // }
        const siteInd = _.findIndex(self.sites, (o) => o._id === item._id);
        if (siteInd !== -1) {
            item.zones = _.filter(item.zones, (o) => !o.disabled);
            self.sites[siteInd] = item;
        }
        self.tableParams.reload();
    }

    $onInit() {
        const self = this;
        self.$http.get('/api/sites/lite')
            .then((response) => {
                self.availableSites = response.data;
                if (self.$state.params.site) {
                    this.$http.get(`/api/sites/${self.$state.params.site}`)
                        .then((foundSite) => {
                            if (foundSite) {
                                self.selectedSite = foundSite.data;
                                self.selectedSite.zones = _.filter(self.selectedSite.zones, (o) => !o.disabled);
                            } else {
                                self.selectedSite = undefined;
                                console.log('Site could not be found.');
                            }
                        });
                } else {
                    // self.selectedSite = self.sites[0];
                }
                self.firstTime = true;
                self.tableParams = new self.NgTableParams({
                    page: 1, // start with first page
                    count: 10, // count per page
                    sorting: {
                        alias: 'asc', // initial sorting
                    },
                }, {
                    total: 0,
                    getData(params) {
                        let order;
                        if (params && params.sorting) {
                            order = params.sorting();
                            self.formattedZones = [];
                            if (self.$state.params.site) {
                                self.$http.get(`/api/sites/${self.$state.params.site}`)
                                    .then((foundSite) => {
                                        if (foundSite) {
                                            self.selectedSite = foundSite.data;
                                            self.selectedSite.zones = _.filter(self.selectedSite.zones, (o) => !o.disabled);
                                            self.selectedSite.zones.forEach((zone) => {
                                                let cUnit = zone.unit;
                                                let cCam = zone.camera;
                                                const unitIsNotObject = zone.unit && !Object.prototype.hasOwnProperty.call(zone.unit, '_id');
                                                const cameraIsNotObject = zone.camera && !Object.prototype.hasOwnProperty.call(zone.camera, '_id');
                                                if (unitIsNotObject && cameraIsNotObject) {
                                                    cUnit = _.find(self.selectedSite.units, (o) => o._id === zone.unit);
                                                    if (cUnit) {
                                                        cCam = _.find(cUnit.cameras, (o) => o._id === zone.camera);
                                                    }
                                                }
                                                zone.camera = cCam;
                                                zone.unit = cUnit;
                                                const formattedZone = OverviewProcessing.formatZone(zone, self.includeCamMetadata);
                                                if (formattedZone) {
                                                    self.formattedZones.push(formattedZone);
                                                }
                                            });
                                            if (self.includeCamMetadata) {
                                                self.checkAndAddCustomFields(self.selectedSite.zones);
                                            }
                                        }
                                    });
                            }
                            return self.formattedZones;
                        }
                        return [];
                    },
                });

                self.selectedColumns = ColumnData.defaultSelectedColumns;
                self.allCols = ColumnData.baseColumns;
                self.cols = ColumnData.baseColumns;
                self.cols.forEach((col) => {
                    col.getValue = self.handleDisplay.bind(this);
                });

                if (self.includeCamMetadata) {
                    const metaCols = ColumnData.cameraMetadataColumns;
                    self.allCols.concat(metaCols);
                    metaCols.forEach((col) => {
                        col.getValue = self.handleDisplay.bind(this);
                    });
                    self.cols.concat(metaCols);
                }
            });
    }

    checkAndAddCustomFields(zones) {
        const self = this;
        zones.forEach((zone) => {
            if (zone.cameraMetadata?.customFields?.length > 0) {
                zone.cameraMetadata.customFields.forEach((field) => {
                    const fieldInd = _.findIndex(self.allCols, (o) => o?.field === `meta_${field.key}`);
                    if (fieldInd === -1) {
                        self.allCols.push({
                            title: `Metadata: ${field.key}`,
                            field: `meta_${field.key}`,
                        });

                        self.cols.push({
                            title: `Metadata: ${field.key}`,
                            field: `meta_${field.key}`,
                            show: false,
                            getValue: self.handleDisplay.bind(this),
                        });
                    }
                });
            }
        });
    }

    handleDisplay(self, col, zone) {
        let html = '';
        switch (col.field) {
        case 'lprRegion':
            if (Array.isArray(zone[col.field])) {
                let list = '';
                const listLength = zone[col.field].length;
                zone[col.field].forEach((region, index) => {
                    list += self.$filter('regionFilter')(region, self.countryList);
                    if (index + 1 !== listLength) {
                        list += ', ';
                    }
                });
                return list;
            }
            return self.$filter('regionFilter')(zone[col.field], self.countryList);

        case 'active':
            if (zone[col.field] === 'Unbounded') {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'vvsecEnabled':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'referenceShot':
            if (zone[col.field]) {
                html += `<img id="reference_${zone._id}" alt="reference_${zone._id}" crossorigin="anonymous" ng-src="${zone[col.field]}" fallback-src style="width:20em">`;
            } else {
                html += '<h1>No Reference Photo</h1>';
            }
            return self.$sce.trustAsHtml(html);

        case 'atHWDec':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'atHWEnc':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'atTCP':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'motionAnalysis':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

            // case "deltaThreshold":
            //     html += `${zone[col.field]}`;
            //     return self.$sce.trustAsHtml(html);

        case 'motionTrigger':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'lineTrip':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'frAnalytics':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'lprAnalytics':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'heatmaps':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'cloudSchedule':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'lsHWDec':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'lsHWEnc':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'lsForceTCP':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'lsActive':
            if (zone[col.field] === 'Unbounded') {
                html += `${zone[col.field]}`;
            } else if (zone[col.field]) {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html
                    += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'motionMinArea':
            if (zone[col.field] === 'Unbounded') {
                return `${zone[col.field]}`;
            }
            return `${zone[col.field]}%`;

        case 'lsType':
            if (zone[col.field] === 'Unbounded') {
                return `${zone[col.field]}`;
            }
            if (zone[col.field] === 'OnlyMotion') {
                return 'Only Motion';
            }
            return `${zone[col.field]}`;

        case 'frMinFaceSize':
            if (zone[col.field] === 'Unbounded') {
                return `${zone[col.field]}`;
            }
            return `${zone[col.field]}%`;

        default:
            // The default cases are those who adopt boolean values
            // "internet || chreosis || ethernet || vpn || usb || wan"
            return zone[col.field];
        }
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    toggleView() {
        const self = this;
        self.cols.forEach((col) => {
            if (!self.selectedColumns.includes(col.title)) {
                col.show = self.overviewMode;
            }
        });
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    selected(item, model) {
        const self = this;
        self.$state.go('.', { site: item._id }, { notify: false })
            .then(() => {
                self.tableParams.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    debug() {
        console.log('DEBUG:', this);
    }

    createSheet(workbook) {
        const self = this;
        const sheet = workbook.addWorksheet('Zone Configuration');
        sheet.pageSetup.horizontalCentered = true;
        sheet.pageSetup.verticalCentered = true;

        OverviewProcessing.addHeaderBlock(sheet, self.allCols, self.currentAccount, self.selectedSite);
        OverviewProcessing.populateData(sheet, self.allCols, self.formattedZones, self);
        OverviewProcessing.processColumnWidths(sheet);
        OverviewProcessing.prepareImages(workbook, sheet, self.formattedZones, self.$document[0]);
    }

    download() {
        const self = this;
        const workbook = new exceljs.Workbook();

        self.createSheet(workbook);

        workbook.xlsx.writeBuffer()
            .then((buffer) => {
                FileSaver.saveAs(new Blob([buffer]), `${self.selectedSite.alias}_${self.moment()
                    .format('DD_MM_YYYY_HH:mm:ss')}.xlsx`);
            })
            .catch((err) => {
                console.log('Error while writing buffer', err);
            });
    }
}

export default angular.module('cameraViewerApp.zoneOverview')
    .component('zoneOverview', {
        template: require('./zoneOverview.html'),
        controller: ZoneOverviewComponent,
        controllerAs: '$ctrl',
    })
    .name;
