import c3 from 'c3';
import _ from 'lodash-es';
import angular from 'angular';
import SettingsController from '../sites/settings/settings.controller';

export class UnitsComponent {
    $http;
    $state;
    socket;
    moment;
    toastr;
    Auth;
    sites;
    cameras;
    NgTableParams;
    $sce;
    $uibModal;

    /* @ngInject */
    constructor(
        $http,
        toastr,
        moment,
        socket,
        NgTableParams,
        $state,
        $scope,
        Auth,
        $sce,
        $uibModal
    ) {
        this.$http = $http;
        this.$state = $state;
        this.socket = socket;
        this.Auth = Auth;
        this.toastr = toastr;
        this.moment = moment;
        this.allAccounts = false;
        this.NgTableParams = NgTableParams;
        this.sites = [];
        this.cameras = [];
        this.$sce = $sce;
        this.$uibModal = $uibModal;
        this.currentRooms = [];
        this.units = [];
        this.filter = '';
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    checkVPN(unit) {
        const headers = {
            'x-js-assume-account':
                typeof unit.accountId == 'object' ? unit.accountId._id : unit.accountId,
        };
        this.$http.post(`/api/units/${unit._id}/checkVPN`, {}, { headers }).then((response) => {
            if (response.data.success) {
                unit.vpn = response.data.vpn;
                unit.sessionStart = response.data.startTime;
                unit.sessionChecked = response.data.updateTime;
                unit.sessionEnd = response.data.stopTime;
                unit.radiusIP = response.data.radiusIP;
                unit.verifiedIP = true;
            }
        });
    }

    requestStatus(unit) {
        this.$http.post(`/api/units/${unit._id}/forceHB`).catch((err) => {
            console.error('Status request failed', err);
        });
    }

    calculateIP(serial) {
        const regExItem = /(\d+)$/; // Updated regex to capture one or more digits at the end of the string
        try {
            const serialNoMatch = regExItem.exec(serial);
            if (!serialNoMatch) {
                console.error('No serial number digits found.');
                return 'Unknown';
            }

            const serial_no = parseInt(serialNoMatch[0], 10);
            let base_oct3 = 1; // Default start for subnet (10.154.1.0)
            let base_oct4 = 0; // This would be incremented to start from .1

            if (serial.startsWith('JS')) {
                // Start from this subnet if 'JS' is found indicating a development device
                base_oct3 = 40; // Development devices start from this subnet (10.154.40.16)
                base_oct4 = 15; // This will be incremented to start from .16
            }

            // IP calculation logic
            for (let i = 0; i < serial_no; i++) {
                base_oct4++;
                if (base_oct4 === 255) {
                    // Avoid .255 address
                    base_oct4 = 1; // Skip to .1 of the next subnet
                    base_oct3++;
                }
            }

            return `10.154.${base_oct3}.${base_oct4}`;
        } catch (e) {
            console.error('IP calculation failed:', e);
            return 'Unknown';
        }
    }

    $onDestroy() {
        const self = this;
        if (self.theUnitEventListener) {
            self.socket.socket.removeListener('unit:save', self.theUnitEventListener);
            self.socket.socket.removeListener('site:save', self.theSiteEventListener);
            self.socket.socket.removeListener('unit:remove', self.theUnitEventListenerRemove);
        }
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;

        const currentAcc = self.Auth.getCurrentAccountSync().accountId;
        self.socket.joinRoom(`${currentAcc}:*:units`);
        self.currentRooms.push(`${currentAcc}:*:units`);
        self.socket.joinRoom(`${currentAcc}:*:sites`);
        self.currentRooms.push(`${currentAcc}:*:sites`);

        self.theUnitEventListener = self.onReceiptOfUnit.bind(self);
        self.theSiteEventListener = self.onReceiptOfSite.bind(self);
        self.theUnitEventListenerRemove = self.onRemoveOfUnit.bind(self);

        self.socket.socket.on('unit:save', self.theUnitEventListener);
        self.socket.socket.on('site:save', self.theSiteEventListener);
        self.socket.socket.on('unit:remove', self.theUnitEventListenerRemove);

        this.selectedColumns = [
            'Alias',
            'Last Activity',
            'Cameras',
            'Ethernet',
            'Assigned',
            'VPN',
            'WLAN',
            'Site',
            'MAC',
            'Serial',
            'FW Version',
        ];

        this.cols = [
            {
                title: 'Account',
                field: 'accountName',
                show: false,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Alias',
                field: 'alias',
                show: true,
                sortable: 'alias',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Last Activity',
                field: 'lastActivity',
                show: true,
                sortable: 'lastActivity',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Activity',
                field: 'activityGraph',
                show: true,
                getValue: this.handleDisplay.bind(this),
            },
            // {
            //     title: 'Total Cameras',
            //     field: 'totalCams',
            //     show: true,
            //     getValue: this.handleDisplay.bind(this),
            // },
            // {
            //     title: 'Live Cameras',
            //     field: 'liveCams',
            //     show: true,
            //     getValue: this.handleDisplay.bind(this),
            // },
            {
                title: 'Cameras',
                field: 'cameras',
                show: true,
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Ethernet',
                field: 'eth',
                show: true,
                sortable: 'lastHB.comms.eth',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'WLAN',
                field: 'wlan',
                show: true,
                sortable: 'lastHB.comms.wlan',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'VPN',
                field: 'vpn',
                show: true,
                sortable: 'lastHB.comms.vpn',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'VPN IP',
                field: 'radiusIP',
                show: this.Auth.hasUserPrivilegeSync('debug'),
                sortable: 'radiusIP',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'FW Version',
                field: 'version',
                show: true,
                sortable: 'version',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Assigned',
                field: 'assigned',
                show: true,
                sortable: 'assigned',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Site',
                field: 'siteAlias',
                show: true,
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'MAC',
                field: 'mac',
                show: true,
                sortable: '_id',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Serial',
                field: 'serial',
                show: true,
                sortable: 'serial',
                getValue: this.handleDisplay.bind(this),
            },
        ];

        this.tableParams = new this.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    alias: 'asc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    const headers = {};
                    if (self.allAccounts) headers['x-js-all-accounts'] = 'true';
                    if (params && params.sorting) {
                        order = params.sorting();
                        const dataPromise = self.$http.get('/api/units', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                skip: (params.page() - 1) * params.count(),
                                limit: params.count(),
                                by: Object.keys(order)[0],
                                order: order[Object.keys(order)[0]],
                                populate: true,
                            },
                            headers,
                        });
                        const countPromise = self.$http.get('/api/units/count', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                            },
                            headers,
                        });
                        return Promise.all([dataPromise, countPromise])
                            .then((response) => {
                                self.units = response[0].data;
                                self.total = response[1].data;
                                params.total(self.total);
                                self.formattedUnits = [];
                                self.units.forEach((unit) => {
                                    self.formattedUnits.push(self.formatData(unit));
                                });
                                return self.formattedUnits;
                            })
                            .catch((err) => {
                                console.error('Error caught when getting data for units: ', err);
                            });
                    }
                    return null;
                },
            }
        );
    }

    onReceiptOfSite(newSite) {
        const self = this;
        const index = _.findIndex(self.units, (o) =>
            o.site?._id ? o.site._id === newSite._id : o.site === newSite._id
        );
        if (index === -1) return;
        const formatIndex = _.findIndex(
            self.formattedUnits,
            (o) => o._id === self.units[index]._id
        );
        if (index !== -1 && formatIndex !== -1) {
            self.units[index].site = newSite;
            self.formattedUnits[formatIndex] = self.formatData(self.units[index]);
        }
    }

    onReceiptOfUnit(newUnit) {
        const self = this;
        const index = _.findIndex(self.units, (o) => o._id === newUnit._id);
        const formatIndex = _.findIndex(self.formattedUnits, (o) => o._id === newUnit._id);
        if (index !== -1 && formatIndex !== -1) {
            newUnit.accountName = self.units[index].accountName;
            self.units[index] = newUnit;
            self.formattedUnits[formatIndex] = self.formatData(self.units[index]);
        }
    }

    onRemoveOfUnit(newUnit) {
        const self = this;
        const index = _.findIndex(self.units, (o) => o._id === newUnit._id);
        const formatIndex = _.findIndex(self.formattedUnits, (o) => o._id === newUnit._id);
        if (index !== -1 && formatIndex !== -1) {
            self.tableParams.reload();
        }
    }

    updateActivityGraph(unitId) {
        const self = this;
        const hourCount = 2;
        const endTime = +self.moment();
        const startTime = +self.moment().subtract(hourCount, 'hours');
        self.$http
            .get('api/heartbeats/activityGraph', {
                params: { unit: unitId, startTime, endTime, hourCount },
            })
            .then((resp) => {
                const graphData = [['xtime'], ['Status']];
                resp.data.forEach((item) => {
                    graphData[0].push([item.t]);
                    graphData[1].push([item.v]);
                });
                console.log(graphData);
                return c3.generate({
                    bindto: `#activityGraph_${unitId.replaceAll(':', '')}`,
                    data: {
                        x: 'xtime',
                        columns: graphData,
                        type: 'bar',
                        onclick(d) {},
                    },
                    size: {
                        height: 32,
                    },
                    padding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                    axis: {
                        y: {
                            show: false,
                            max: 2,
                            min: 0,
                            tick: {
                                values: [0, 1, 2],
                            },
                            padding: { top: 0, bottom: 0, left: 0, right: 0 },
                        },
                        x: {
                            show: false,
                            padding: { top: 0, bottom: 0, left: 0, right: 0 },
                        },
                    },
                    legend: {
                        show: false,
                    },
                    tooltip: {
                        show: true,
                        format: {
                            title: function (x, index) {
                                return `${self.moment(x).fromNow()}`;
                            },
                            value: function (value, ratio, id, index) {
                                switch (value) {
                                    case 0:
                                        return 'Offline';
                                    case 1:
                                        return 'Online';
                                    case 2:
                                        return 'Boot';
                                    default:
                                        return 'Other';
                                }
                            },
                        },
                        position: function (data, width, height, element) {
                            return { top: 30, left: 0 };
                        },
                    },
                    bar: {
                        width: {
                            ratio: 1.2,
                        },
                    },
                    color: {
                        pattern: ['#2077b3', '#124466'],
                    },
                });
            });
    }

    handleDisplay(self, col, unit) {
        let html = '';
        switch (col.field) {
            case 'alias':
                if (self.Auth.hasRoleSync('secuvue.UnitManagement.ShellScript')) {
                    return self.$sce.trustAsHtml(
                        `<div style="cursor:pointer; color:navy;" ng-click="$ctrl.openSSH(unit)">${unit[col.field]}</div>`
                    );
                }
                return unit[col.field];

            case 'accountName':
                return unit[col.field];

            case 'liveCams':
                return unit.down ? 0 : unit[col.field];

            case 'totalCams':
                return unit[col.field];

            case 'cameras':
                return `${unit.down ? 0 : unit.liveCams} / ${unit.totalCams ? unit.totalCams : 0}`;

            case 'version':
                if (unit[col.field]) {
                    if (unit[col.field] === 'Python') {
                        return 'Legacy';
                    }
                    return `V${unit[col.field]}`;
                }
                return `unknown`;

            case 'imei':
                return unit[col.field];

            case 'eth':
                if (unit[col.field]) {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-check-circle text-success"></i>' +
                        '</span>';
                } else {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-ban text-danger"></i>' +
                        '</span>';
                }
                return self.$sce.trustAsHtml(html);

            case 'vpn':
                html += '<span class="fa-lg">';
                if (unit[col.field]) {
                    if (unit.sessionStart) {
                        html +=
                            '<i uib-tooltip="Unit online since {{unit.sessionStart | date:\'yyyy-MM-dd HH:mm:ss\'}}" class="fa fa-check-circle text-success"></i>';
                    } else {
                        html +=
                            '<i uib-tooltip="VPN status unchecked" class="fa fa-question-circle text-success"></i>';
                    }
                } else if (unit.sessionStart) {
                    html +=
                        '<i uib-tooltip="Unit offline since {{unit.sessionEnd | date:\'yyyy-MM-dd HH:mm:ss\'}}" class="fa fa-ban text-danger"></i>';
                } else {
                    html +=
                        '<i uib-tooltip="VPN status unchecked" class="fa fa-question-circle text-danger"></i>';
                }
                html += '</span>';
                html +=
                    '<span style="margin-left:10px;" class="fa-lg">' +
                    '<i uib-tooltip="Check VPN Status" ng-click="$ctrl.checkVPN(unit)" class="fa fa-sync-alt"></i>' +
                    '</span>';
                return self.$sce.trustAsHtml(html);

            case 'radiusIP':
                html +=
                    '<span>' +
                    `${unit[col.field]}<small ng-if="!unit.verifiedIP">*</small>` +
                    '</span>';
                return self.$sce.trustAsHtml(html);

            case 'wlan':
                if (unit[col.field]) {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-check-circle text-success"></i>' +
                        '</span>';
                } else {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-ban text-danger"></i>' +
                        '</span>';
                }
                return self.$sce.trustAsHtml(html);

            case 'lastActivity':
                if (!unit.down) {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-check-circle text-success"></i> ' +
                        '</span>';
                } else {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-ban text-danger"></i> ' +
                        '</span>';
                }

                if (unit.lastActivity) {
                    html +=
                        '<span uib-tooltip="{{unit.lastActivity | date:\'yyyy-MM-dd HH:mm:ss\'}}" tooltip-append-to-body="true" am-time-ago="unit.lastActivity"></span>';
                } else {
                    html += '<span>No last activity</span>';
                }
                html +=
                    '<span style="margin-left:10px;" class="fa-lg pull-right">' +
                    '<i uib-tooltip="Request Status" ng-click="$ctrl.requestStatus(unit)" class="fa fa-sync-alt"></i>' +
                    '</span>';
                return self.$sce.trustAsHtml(html);

            case 'activityGraph':
                html += `<div id="activityGraph_${unit._id.replaceAll(':', '')}" style="max-width: 16ch; min-width: 16ch; height: 1.2em;" ng-click="$ctrl.updateActivityGraph(unit._id)"></div>`;
                return self.$sce.trustAsHtml(html);

            case 'siteAlias':
                if (unit.site) {
                    html +=
                        `<span style="cursor:pointer; color:navy;" ng-click="$ctrl.openSitePage(unit.site)">${unit[col.field] || 'Unassigned'}</span>` +
                        '<span style="margin-left:10px;" class="fa-lg pull-right">' +
                        '<i style="cursor:pointer;" class="glyphicon glyphicon-cog" ng-click="$ctrl.openSiteSettings(unit)"></i>' +
                        '</span>';
                }
                return self.$sce.trustAsHtml(html);

            case 'serial':
                return unit[col.field];

            case 'mac':
                return unit._id;

            default:
                // The default cases are those who adopt boolean values
                // "internet || chreosis || ethernet || vpn || usb || wan"
                if (unit[col.field]) {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-check-circle text-success"></i>' +
                        '</span>';
                } else {
                    html +=
                        '<span class="fa-lg">' +
                        '<i class="fa fa-ban text-danger"></i>' +
                        '</span>';
                }

                return self.$sce.trustAsHtml(html);
        }
    }

    formatData(unit) {
        const self = this;

        if (!Object.prototype.hasOwnProperty.call(unit, 'lastHB')) {
            if (unit.site && Object.prototype.hasOwnProperty.call(unit.site, 'units')) {
                const unitIndex = _.findIndex(unit.site.units, (o) => o._id === unit._id);
                unit.lastHB = unit.site.units[unitIndex].lastHB;
            }
        }

        if (unit.site && Object.prototype.hasOwnProperty.call(unit.site, 'alias')) {
            unit.siteAlias = unit.site.alias;
        } else if (unit.site) {
            const site = _.find(self.sites, (o) => o._id === unit.site);
            if (site) {
                unit.siteAlias = site.alias;
            }
        }

        unit.timeAgo = _.throttle(self.getTimeAgo, 10000);
        if (unit.assigned === undefined) {
            unit.assigned = false;
        }
        if (unit.down === undefined) {
            unit.down = !self.isOnline(unit);
        }
        if (unit.lastHB && unit.lastHB.comms) {
            unit.eth = unit.lastHB.comms.eth;
            unit.vpn = unit.lastHB.comms.vpn;
            unit.wlan = unit.lastHB.comms.wlan;
        }
        if (unit.lastHB) {
            let liveCams = 0;
            let totalCams = 0;
            if (unit.site && unit.site.units) {
                const thisUnit = _.find(
                    unit.site.units,
                    (o) => o._id.toString() === unit._id.toString()
                );
                if (thisUnit) {
                    thisUnit?.cameras?.forEach((cam) => {
                        if (cam.down === false && cam.hungCam === false) {
                            liveCams++;
                        }
                    });
                    totalCams = thisUnit?.cameras?.length;
                }
            } else {
                if (unit.lastHB.cameras) {
                    unit.lastHB.cameras.forEach((cam) => {
                        if (cam.state === 'live') {
                            liveCams++;
                        }
                    });
                    totalCams = unit.lastHB.cameras.length;
                }
            }
            unit.liveCams = liveCams;
            unit.totalCams = totalCams;
        }
        if (unit.accountId && unit.accountId.name) {
            unit.accountName = unit.accountId.name;
        }
        unit.radiusIP = self.calculateIP(unit.serial);
        unit.verifiedIP = false;

        return unit;
    }

    isOnline(unit) {
        if (unit.lastActivity) {
            return this.moment.utc().diff(this.moment.utc(unit.lastActivity), 'minutes') < 10;
        }
        return false;
    }

    getTimeAgo(unit, moment) {
        if (unit.lastActivity) {
            const minutes = Math.abs(moment.utc().diff(moment.utc(unit.lastActivity), 'minutes'));
            if (minutes === 1) {
                return '1 minute ago';
            }
            if (minutes > 1) {
                return `${minutes.toString()} minutes ago`;
            }
            return 'less than a minute ago';
        }
        return 'no last Activity';
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    moveUnits() {
        const self = this;
        const modalInstance = self.$uibModal.open({
            component: 'moveUnit',
            backdrop: 'static',
            keyboard: false,
        });
        modalInstance.result.then(() => {});
    }

    openSSH(unit) {
        const self = this;

        self.$uibModal
            .open({
                component: 'shellModal',
                backdrop: 'static',
                size: 'lg',
                keyboard: false,
                resolve: {
                    unit() {
                        return unit;
                    },
                },
            })
            .result.then(() => {});
    }

    toggleAcc() {
        const self = this;
        const ind = _.findIndex(self.cols, (o) => o.title === 'Account');

        self.allAccounts = !self.allAccounts;

        self.cols[ind].show = self.allAccounts;

        self.tableParams.reload();
    }

    openSitePage(site) {
        const self = this;
        let siteId;
        if (typeof site === 'string') {
            siteId = site;
        } else if (typeof site === 'undefined') {
            return;
        } else {
            siteId = site?._id?.toString();
        }
        self.$state.go('main.site', { id: siteId }).catch((err) => {
            console.log(err);
        });
    }

    openSiteSettings(unit) {
        const self = this;
        self.$http.get(`/api/sites/${unit.site._id}`).then((response) => {
            const unitSite = response.data;
            self.$uibModal.open({
                template: require('../sites/settings/settings.html'),
                backdrop: 'static',
                keyboard: false,
                size: 'xlg',
                controller: SettingsController,
                controllerAs: '$ctrl',
                resolve: {
                    currentSite() {
                        return unitSite;
                    },
                    jumpToZone() {
                        return undefined;
                    },
                    jumpToUnit() {
                        return unit._id;
                    },
                },
            });
        });
    }
}

export default angular.module('cameraViewerApp.units')
    .component('units', {
        template: require('./units.html'),
        controller: UnitsComponent,
        controllerAs: '$ctrl',
    })
    .name;
