// SHOW THE FOLLOWING
// No comms units
// No comms cameras
// Disks at 90%
// CPU/Mem runaway
// Disks not reachable
//

import _ from 'lodash-es';
import angular from 'angular';
import exceljs from 'exceljs';
import FileSaver from 'file-saver';

export class MaintenanceComponent {
    $http;
    $state;
    socket;
    moment;
    toastr;
    Auth;
    sites;
    cameras;

    /* @ngInject */
    constructor($http, toastr, $timeout, moment, socket, $state, $scope, Auth) {
        this.$http = $http;
        this.$state = $state;
        this.socket = socket;
        this.Auth = Auth;
        this.$timeout = $timeout;
        this.toastr = toastr;
        this.moment = moment;
        this.currentRooms = [];
        this.sites = [];
        this.debugSites = [];
        this.cameras = [];
        this.loading = true;
        this.isProcessing = false;
        this.allAccounts = false;
        this.accountsWithFaults = [];
        this.exportColumns = [
            'Site',
            'Site Last Activity',
            'Item Type',
            'Item Name',
            'Item Issue',
            'Item Last Activity',
        ];
        this.siteColumns = [
            'Site',
            'Site Last Activity',
        ];
        this.unitColumns = [
            'Unit Serial',
            'Last Activity',
            'Site',
        ];
        this.zoneColumns = [
            'Zone Name',
            'Last Activity',
            'Unit Serial',
            'Site',
        ];
        this.categorisedFaults = {
            siteNotConfigured: {
                title: 'Unconfigured Sites',
                type: 'site',
                items: [],
            },
            siteNoUnits: {
                title: 'Sites With No Units',
                type: 'site',
                items: [],
            },
            unitNotConfigured: {
                title: 'Unconfigured Units',
                type: 'unit',
                items: [],
            },
            unitNoZonesConfigured: {
                title: 'Units With No Zones',
                type: 'unit',
                items: [],
            },
            zoneNotConfigured: {
                title: 'Unconfigured Zones',
                type: 'zone',
                items: [],
            },
            unitLocalStorage: {
                title: 'Unit Local Storage',
                type: 'unit',
                items: [],
            },
            zoneLocalStorage: {
                title: 'Local Storage',
                type: 'zone',
                items: [],
            },
            unitBootLoop: {
                title: 'Unit Boot Loop',
                type: 'unit',
                items: [],
            },
            zoneAuth: {
                title: 'Authentication Issues',
                type: 'zone',
                items: [],
            },
            siteDown: {
                title: 'Down Sites',
                type: 'site',
                items: [],
            },
            unitDown: {
                title: 'Down Units',
                type: 'unit',
                items: [],
            },
            zoneDown: {
                title: 'Down Cameras',
                type: 'zone',
                items: [],
            },
            rootDown: {
                title: 'Storage Down',
                type: 'unit',
                items: [],
            },
        };
    }

    $onDestroy() {
        // const self = this;
        // this.socket.socket.removeListener(self.analyzeSites);
        // if(self.currentRooms.length > 0) {
        //     self.currentRooms.forEach(room => {
        //         self.socket.leaveRoom(room);
        //     });
        // }
    }

    $onInit() {
        const self = this;
        self.refresh();
        // self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:maintenance:sites`);
        // self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:maintenance:sites`);
        // self.socket.socket.on('maintenance', self.analyzeSites.bind(this));
    }

    refresh() {
        const self = this;
        self.loading = true;
        self.$http.get('api/maintenance/sites', { params: { allAccounts: self.allAccounts } })
            .then((response) => {
                self.sites = [];
                self.debugSite = [];
                Object.values(self.categorisedFaults).forEach((faultList) => {
                    faultList.items = [];
                });
                self.hasErrors = response.data.length > 0;
                response.data.forEach(self.analyzeSites.bind(self));
                self.loading = false;
            });
    }

    toggleAll() {
        const self = this;
        self.allAccounts = !self.allAccounts;
        self.refresh();
    }

    analyzeSites(site) {
        const self = this;
        // console.log("Socket called", site.alias, site);
        // Loop through sites to see which should be shown in maintenance
        site.faults = [];
        const siteEntry = {
            id: site._id,
            alias: site.alias,
            lastActivity: site.lastActivity,
            account: site.accountname,
        };

        if (site.units?.length === 0) {
            site.faults.push('Site Has No Units');
            self.categorisedFaults.siteNoUnits.items.push(siteEntry);
        }

        if (!site.configured) {
            site.faults.push('Site Not Configured');
            self.categorisedFaults.siteNotConfigured.items.push(siteEntry);
        }
        if (site.down) {
            site.faults.push('Site Down');
            self.categorisedFaults.siteDown.items.push(siteEntry);
        }

        if (site.initialized && site.enabled) {
            site.faultyZones = [];
            site.faultyUnits = [];
            site.debugFaultyZones = [];

            site.units.forEach((unit) => {
                // console.log(unit);
                if (unit.enabled) {
                    if (!unit.configured || unit.storageDown || unit.down || unit.bootCounter >= 5 || unit.cameras?.length === 0) {
                        unit.faults = [];
                        const unitEntry = {
                            id: unit._id,
                            alias: unit.serial,
                            lastActivity: unit.lastActivity,
                            siteId: site._id,
                            site: site.alias,
                            account: site.accountname,
                        };
                        if (unit.cameras?.length === 0) {
                            unit.faults.push('No Zones Configured');
                            self.categorisedFaults.unitNoZonesConfigured.items.push(unitEntry);
                        }
                        if (!unit.configured) {
                            unit.faults.push('Not Configured');
                            self.categorisedFaults.unitNotConfigured.items.push(unitEntry);
                        }
                        if (unit.down) {
                            unit.faults.push('Unit Down');
                            self.categorisedFaults.unitDown.items.push(unitEntry);
                        }
                        if (unit.bootCounter >= 5) {
                            unit.faults.push('Boot Loop');
                            self.categorisedFaults.unitBootLoop.items.push(unitEntry);
                        }
                        if (unit.storageDown) {
                            unit.faults.push('Local Storage Down');
                            self.categorisedFaults.unitLocalStorage.items.push(unitEntry);
                            unit.downStorages = [];
                            Object.keys(unit.lastHB.roots)
                                .forEach((key) => {
                                    if (!unit.lastHB.roots[key].writable) {
                                        unit.downStorages.push(unit.lastHB.roots[key].id);
                                    }
                                });
                        }
                        site.faultyUnits.push(_.omit(unit, ['cameras', 'availableCameras']));
                    }
                    unit.cameras?.forEach((cam) => {
                        if (cam.enabled) {
                            if (!cam.configured || cam.authenticationFailed || cam.down) {
                                const faultyZoneInd = _.findIndex(site.zones, (o) => o.camera === cam._id);
                                if (faultyZoneInd !== -1) {
                                    const faultyZone = _.cloneDeep(site.zones[faultyZoneInd]);
                                    faultyZone.camera = _.omit(cam, ['configuration', 'profiles']);
                                    faultyZone.unit = _.omit(unit, ['cameras', 'lastHB', 'availableCameras', 'localstorage', 'usageData']);
                                    faultyZone.faults = [];
                                    const zoneEntry = {
                                        id: faultyZone._id,
                                        alias: faultyZone.alias,
                                        lastActivity: faultyZone.camera.lastActivity,
                                        siteId: site._id,
                                        site: site.alias,
                                        unitId: faultyZone.unit.id,
                                        unit: faultyZone.unit.serial,
                                        account: site.accountname,
                                    };

                                    if (!cam.configured) {
                                        faultyZone.faults.push('Not Configured');
                                        self.categorisedFaults.zoneNotConfigured.items.push(zoneEntry);
                                    }
                                    if (cam.authenticationFailed) {
                                        faultyZone.authenticationFailed = cam.authenticationFailed;
                                        faultyZone.faults.push('Authentication Failed');
                                        self.categorisedFaults.zoneAuth.items.push(zoneEntry);
                                    }
                                    if (cam.down && !faultyZone.authenticationFailed) {
                                        faultyZone.faults.push('Camera Down');
                                        self.categorisedFaults.zoneDown.items.push(zoneEntry);
                                    }
                                    site.faultyZones.push(faultyZone);
                                }
                            }
                            if (cam.localstorageDown || cam.hungCam) {
                                const faultyZoneInd = _.findIndex(site.zones, (o) => o.camera === cam._id);
                                if (faultyZoneInd !== -1) {
                                    const faultyZone = _.cloneDeep(site.zones[faultyZoneInd]);
                                    faultyZone.camera = _.omit(cam, ['configuration', 'profiles']);
                                    faultyZone.unit = _.omit(unit, ['cameras', 'lastHB', 'availableCameras', 'localstorage', 'usageData']);
                                    faultyZone.faults = [];
                                    if (cam.localstorageDown) {
                                        faultyZone.faults.push('Local Storage Hanging');
                                    }
                                    if (cam.hungCam) {
                                        faultyZone.faults.push('Camera Hanging');
                                    }
                                    site.debugFaultyZones.push(faultyZone);
                                }
                            }
                        }
                    });
                }
            });
            const currentSiteIndex = _.findIndex(self.sites, (o) => o._id === site._id);
            const currentSiteDebugIndex = _.findIndex(self.debugSites, (o) => o._id === site._id);
            if (currentSiteDebugIndex >= 0) {
                if (site.debugFaultyZones.length > 0) {
                    self.debugSites[currentSiteDebugIndex] = site;
                } else {
                    self.debugSites.splice(currentSiteDebugIndex, 1);
                }
            } else if (site.debugFaultyZones.length > 0) {
                self.debugSites.push(site);
            }
            if (currentSiteIndex >= 0) {
                if (site.faultyUnits.length > 0 || site.faultyZones.length > 0) {
                    self.sites[currentSiteIndex] = site;
                } else {
                    self.sites.splice(currentSiteIndex, 1);
                }
            } else if (site.faultyUnits.length > 0 || site.faultyZones.length > 0 || site.faults.length > 0) {
                self.sites.push(site);
                if (!_.includes(self.accountsWithFaults, site.accountname)) {
                    self.accountsWithFaults.push(site.accountname);
                }
            }
        }
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    formatDate(date) {
        if (!date) {
            return 'Never';
        }
        return this.moment(date)
            .local()
            .format('ll LTS');
    }

    formatDateAgo(date) {
        if (!date) {
            return 'Never';
        }
        return this.moment(date).fromNow();
    }

    formatSite(site) {
        const downtime = this.moment()
            .subtract('minutes', 10);
        site.down = site.lastActivity ? this.moment.utc(site.lastActivity)
            .isBefore(downtime) : true;
        return site;
    }

    // TODO: Remove configures?
    configureSite(site) {
        const self = this;
        self.$state.go('main.site', { id: site._id })
            .catch((err) => {
                console.log(err);
            });
    }

    configureCamera(camera) {
        const self = this;
        self.$state.go('main.site', { id: camera.site, filter: [camera._id] })
            .catch((err) => {
                console.log(err);
            });
    }

    download() {
        const self = this;
        self.isProcessing = true;
        const account = self.Auth.getCurrentAccountSync();
        const workbook = new exceljs.Workbook();
        const fullSheet = workbook.addWorksheet('Maintenance Stack');
        fullSheet.pageSetup.horizontalCentered = true;
        fullSheet.pageSetup.verticalCentered = true;
        const dataRows = [self.allAccounts ? ['Account', ...self.exportColumns] : self.exportColumns];

        const addRow = function (site, itemType, itemName, itemFaults, itemActivity) {
            itemFaults.forEach((issue) => {
                let rowEntry = [
                    site.alias,
                    self.formatDate(site.lastActivity),
                    itemType,
                    itemName,
                    issue,
                    itemActivity,
                ];
                if (self.allAccounts) {
                    rowEntry = [site.accountname, ...rowEntry];
                }
                dataRows.push(rowEntry);
            });
        };

        const addHeader = function (rowSet, sheet) {
            const requestDate = self.moment()
                .format('DD-MM-YYYY HH:mm:ss');
            let accList = account.name;
            if (self.allAccounts) {
                accList = '';
                self.accountsWithFaults.forEach((acc, idx) => {
                    accList += acc;
                    if (idx + 1 !== self.accountsWithFaults.length) {
                        accList += ', ';
                    }
                });
            }
            rowSet.unshift(['Date & Time:', requestDate]);
            rowSet.unshift([self.allAccounts ? 'Accounts:' : 'Account:', accList]);
            rowSet.forEach((row) => {
                sheet.addRow(row);
            });
        };

        const doFormatting = function (sheet) {
            sheet.getRow(1).font = { italic: true };
            sheet.getRow(2).font = { italic: true };
            sheet.getRow(3).font = { bold: true };
            // Column auto-width and alignment
            sheet.columns.forEach((column) => {
                let dataMax = 0;
                column.alignment = { vertical: 'top', horizontal: 'center' };
                column.values.forEach((value, index) => {
                    // Exclude row 1 and 2 as they have cells that are allowed to overlap.
                    if (value && value.length && index !== 1 && index !== 2) {
                        const columnLength = value.length;
                        if (columnLength > dataMax) {
                            dataMax = columnLength + 1;
                        }
                    }
                });
                column.width = dataMax < 10 ? 10 : dataMax;
            });
            // Alignment exceptions
            sheet.getRow(1).alignment = { horizontal: 'left' };
            sheet.getRow(2).alignment = { horizontal: 'left' };
            sheet.getCell('A1').alignment = { horizontal: 'right' };
            sheet.getCell('A2').alignment = { horizontal: 'right' };
        };

        self.sites.forEach((site) => {
            if (site.faults.length > 0) {
                addRow(site, 'Site', site.alias, site.faults, self.formatDate(site.lastActivity));
            }
            site.faultyUnits.forEach((unit) => {
                addRow(site, 'Unit', unit.serial, unit.faults, self.formatDate(unit.lastActivity));
            });
            site.faultyZones.forEach((zone) => {
                addRow(site, 'Zone', zone.alias, zone.faults, self.formatDate(zone.camera.lastActivity));
            });
        });

        addHeader(dataRows, fullSheet);
        doFormatting(fullSheet);

        Object.values(self.categorisedFaults).forEach((faultList) => {
            if (faultList.items.length > 0) {
                const currentSheet = workbook.addWorksheet(faultList.title);
                currentSheet.pageSetup.horizontalCentered = true;
                currentSheet.pageSetup.verticalCentered = true;

                let rowData;
                if (faultList.type === 'site') {
                    rowData = [self.allAccounts ? ['Account', ...self.siteColumns] : self.siteColumns];
                } else if (faultList.type === 'unit') {
                    rowData = [self.allAccounts ? ['Account', ...self.unitColumns] : self.unitColumns];
                } else {
                    rowData = [self.allAccounts ? ['Account', ...self.zoneColumns] : self.zoneColumns];
                }

                faultList.items.forEach((item) => {
                    let rowEntry;
                    if (faultList.type === 'site') {
                        rowEntry = [
                            item.alias,
                            self.formatDate(item.lastActivity),
                        ];
                    } else if (faultList.type === 'unit') {
                        rowEntry = [
                            item.alias,
                            self.formatDate(item.lastActivity),
                            item.site,
                        ];
                    } else {
                        rowEntry = [
                            item.alias,
                            self.formatDate(item.lastActivity),
                            item.unit,
                            item.site,
                        ];
                    }

                    if (self.allAccounts) {
                        rowEntry = [item.account, ...rowEntry];
                    }

                    rowData.push(rowEntry);
                });

                addHeader(rowData, currentSheet);
                doFormatting(currentSheet);
            }
        });

        workbook.xlsx.writeBuffer()
            .then((buffer) => {
                const timestamp = self.moment().format('DD_MM_YYYY_HH:mm:ss');
                FileSaver.saveAs(new Blob([buffer]), `${self.allAccounts ? 'Full_Estate' : account.name}_Maintenance_Stack_${timestamp}.xlsx`);
                self.isProcessing = false;
            })
            .catch((err) => {
                self.toastr.error('Failed to export', { preventOpenDuplicates: true });
                console.log('Error while writing buffer', err);
                self.isProcessing = false;
            });
    }
}

export default angular.module('cameraViewerApp.maintenance')
    .component('maintenance', {
        template: require('./maintenance.html'),
        controller: MaintenanceComponent,
        controllerAs: '$ctrl',
    })
    .name;
