export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.maintenance', {
            url: '/maintenance',
            views: {
                'content@main': {
                    template: '<maintenance></maintenance>',
                },
            },
            authenticate: 'Maintenance',
            data: {
                title: 'Maintenance',
            },
        });
}
